import React, { ChangeEvent, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Typography } from '@getgo/chameleon-web-react-wrapper';
import { PresenceBusyInactiveIcon } from '@getgo/chameleon-icons/react';
import styles from './UpdateDrawer.module.scss';
import Schedule from './Schedule';
import ForceRebootSwitcher from './force-reboot/ForceRebootSwitcher';
import ForceRebootDelayPicker from './force-reboot/ForceRebootDelayPicker';
import ForceRebootDelayOption from './force-reboot/ForceRebootDelayOption';
import Drawer from '../../../core/components/drawer/Drawer';
import useFeatureSwitch from '../../../core/feature-switch/use-feature-switch.hook';
import { FeatureSwitch } from '../../../core/models/feature-switch';
import { getNormalizedCurrentDateStr, normalizeDateTime } from '../../../core/utilities/utilities';
import Device from '../../../core/models/Device';
import {
  numberOfAppUpdates,
  numberOfMandatoryWindowsUpdates,
  numberOfOptionalWindowsUpdates,
  numberOfUpdatesRequiringReboot,
  numberOfWindowsUpdates
} from '../CompanyUpdatesOverview/DeviceUpdatesStatistics';
import OnlineStatus from '../../models/OnlineStatus';
import InstallButton from './InstallButton';
import { navigate } from '../../../core/services/navigation.service';
import logger from '../../../core/services/logger.service';
import UpdateInstallGuard from '../../hooks/UpdateInstallGuard';
import WingetUpdateHandle from '../../models/WingetUpdateHandle';
import { useSnackbar } from '../../../core/components/snackbar/SnackbarProvider';
import updateScheduler from '../../services/UpdateScheduler';
import WindowsUpdateHandle from '../../models/WindowsUpdateHandle';
import DeviceUpdates from '../../models/DeviceUpdates';
import { splitUpdatesAndDevices } from './DeviceUpdatesUtil';
import { ITextFieldComponent } from '@getgo/chameleon-web';
import { areUpdateCapabilitiesLimited } from '../../utilities/device-utilities';
import { useDialog } from '../../../core/components/dialogs/DialogProvider';
import { DeviceUpdatesInstalledEvent } from '../../../core/models/UserTrackingEvents';

export interface UpdateDrawerProps {
  onClose: () => void;
  selectedDeviceUpdates: DeviceUpdates[];
  isInstallationPending: boolean;
  setInstallationPending: (isPending: boolean) => void;
  installWindowsUpdates: boolean;
  installOptionalWindowsUpdates: boolean;
  installAppUpdates: boolean;
  addUpdatesInProgress?: (updates: { id: string; version?: string }[]) => void;
}

export interface ForceRebootOptions {
  isForceRebootSwitchChecked: boolean;
  forceRebootDelayOption: string;
}

export default function UpdateDrawer({
  children,
  onClose,
  selectedDeviceUpdates,
  isInstallationPending,
  setInstallationPending,
  installWindowsUpdates,
  installOptionalWindowsUpdates,
  installAppUpdates,
  addUpdatesInProgress
}: PropsWithChildren<UpdateDrawerProps>): ReactElement {
  const { t } = useTranslation();
  const dialog = useDialog();

  const { uniqueUpdates, selectedDevices: targetDevices } = splitUpdatesAndDevices(selectedDeviceUpdates);

  const isSchedulingFeatureEnabled = !!useFeatureSwitch(FeatureSwitch.Scheduling);
  const isForceRebootPossible =
    !!useFeatureSwitch(FeatureSwitch.ForceReboot) && numberOfUpdatesRequiringReboot(uniqueUpdates) > 0;
  const isOfflineDeviceSelected = targetDevices.some(d => d.onlineStatus == OnlineStatus.Offline);

  const initialUpdateJobName = t('updateDrawer.jobName.installUpdates');
  const [userProvidedJobName, setUserProvidedJobName] = useState<string>(initialUpdateJobName);
  const [userEnabledScheduling, setUserEnabledScheduling] = useState(
    () => isOfflineDeviceSelected && isSchedulingFeatureEnabled
  );
  const isScheduled = userEnabledScheduling || isOfflineDeviceSelected;
  const [scheduleDateTime, setScheduleDateTime] = useState(getNormalizedCurrentDateStr);
  const [forceRebootEnabled, setForceRebootEnabled] = useState<boolean>(false);
  const [forceRebootDelayOption, setForceRebootDelayOption] = useState<ForceRebootDelayOption>(
    ForceRebootDelayOption.Once
  );
  const canInstallNow = !userEnabledScheduling || !!scheduleDateTime;

  const updateInstallGuard = UpdateInstallGuard.useForDevice();
  const snackbar = useSnackbar();

  const onScheduleDateTimeChange = (dateString?: string): void =>
    setScheduleDateTime(dateString ?? getNormalizedCurrentDateStr());

  const onInstallationTriggered = useCallback(
    async (
      updateJobDisplayName: string,
      targetDevices: Device[],
      scheduleDateTime: string,
      forceRebootOptions: ForceRebootOptions,
      installWindowsUpdates: boolean,
      installOptionalWindowsUpdates: boolean,
      installAppUpdates: boolean
    ): Promise<void> => {
      if (updateInstallGuard()) {
        return;
      }
      setInstallationPending(true);

      try {
        const createJobResponse = await updateScheduler.scheduleUpdate(
          {
            displayName: updateJobDisplayName,
            devices: targetDevices,
            scheduleDateTime,
            isScheduled,
            windowsUpdate:
              numberOfWindowsUpdates(uniqueUpdates) == 0
                ? undefined
                : {
                    updateIds:
                      targetDevices.length == 1
                        ? uniqueUpdates
                            .filter(update => update.type == 'windows-update')
                            .map(update => (update as WindowsUpdateHandle).id)
                        : [],
                    installMandatoryUpdates: installWindowsUpdates,
                    forceReboot: forceRebootOptions.isForceRebootSwitchChecked,
                    forceRebootMaxDelaysCount: Number(forceRebootOptions.forceRebootDelayOption),
                    installOptionalUpdates: installOptionalWindowsUpdates
                  },
            applicationUpdates:
              installAppUpdates && numberOfAppUpdates(uniqueUpdates) != 0
                ? selectedDeviceUpdates.map(deviceStatus => ({
                    deviceId: deviceStatus.device?.id ?? '',
                    updates: deviceStatus.updates
                      .filter(update => update.type == 'winget-update')
                      .map(update => ({
                        packageId: (update as WingetUpdateHandle).appId,
                        packageSource: 'winget', //TODO
                        targetPackageVersion: (update as WingetUpdateHandle).targetVersion
                      }))
                  }))
                : undefined
          },
          new DeviceUpdatesInstalledEvent(
            updateJobDisplayName,
            targetDevices.length,
            installWindowsUpdates
              ? numberOfMandatoryWindowsUpdates(uniqueUpdates) +
                Number(installOptionalWindowsUpdates) * numberOfOptionalWindowsUpdates(uniqueUpdates)
              : 0,
            installAppUpdates ? numberOfAppUpdates(uniqueUpdates) : 0,
            isScheduled
          )
        );

        snackbar.show({
          title: !scheduleDateTime
            ? t('snackbar.installTitle', { jobDisplayName: updateJobDisplayName })
            : t('snackbar.installTitleScheduled', { jobDisplayName: updateJobDisplayName }),
          actions: (
            <Button
              size="small"
              variant="neutral-inverse"
              onClick={() =>
                navigate({ path: '/updates/:updateJobId', params: { updateJobId: createJobResponse.id } })
              }>
              {t('snackbar.actions.seeDetails')}
            </Button>
          ),
          children: scheduleDateTime
            ? t('snackbar.installDetails', { scheduledDate: scheduleDateTime })
            : t('snackbar.installDetailsNow')
        });

        if (addUpdatesInProgress) {
          addUpdatesInProgress(
            uniqueUpdates.map(update => ({
              id: update.type == 'windows-update' ? update.id : update.appId,
              version: update.type == 'winget-update' ? update.targetVersion : '',
              ...(scheduleDateTime ? { scheduleDateTime: normalizeDateTime(scheduleDateTime) } : {})
            }))
          );
        }
      } catch (error) {
        logger.logError(error as Error);
      } finally {
        setInstallationPending(false);
        onClose();
      }
    },
    [
      addUpdatesInProgress,
      onClose,
      selectedDeviceUpdates,
      setInstallationPending,
      snackbar,
      t,
      uniqueUpdates,
      updateInstallGuard
    ]
  );

  return (
    <Drawer
      title={t('updateDrawer.selectedForUpdate')}
      show={true}
      onClose={onClose}
      className={styles.updateDrawer}
      footer={
        <>
          <InstallButton
            isScheduled={isScheduled}
            selectedUpdates={uniqueUpdates}
            isLoading={isInstallationPending}
            disabled={!canInstallNow || isInstallationPending}
            onClick={() => {
              const installFunc = (): Promise<void> =>
                onInstallationTriggered(
                  userProvidedJobName,
                  targetDevices,
                  scheduleDateTime,
                  {
                    isForceRebootSwitchChecked: forceRebootEnabled,
                    forceRebootDelayOption
                  },
                  installWindowsUpdates,
                  installOptionalWindowsUpdates,
                  installAppUpdates
                );
              const devicesWithLimitedCapabilities = targetDevices.filter(device =>
                areUpdateCapabilitiesLimited(device)
              );
              if (devicesWithLimitedCapabilities.length > 0) {
                dialog.showLimitedUpdateCapabilitiesDialog(targetDevices, installFunc);
              } else {
                installFunc();
              }
            }}
          />
          <Button variant="neutral" fullWidth size="large" leadingIcon={<PresenceBusyInactiveIcon />} onClick={onClose}>
            {t('updateDrawer.clearSelection')}
          </Button>
        </>
      }>
      <section>{children}</section>
      <section>
        <TextField
          labelId="display name"
          fullwidth
          required
          error={!userProvidedJobName}
          helperText={!userProvidedJobName ? t('updateDrawer.helperText') : ''}
          onChange={({ target }: ChangeEvent<ITextFieldComponent>) => {
            setUserProvidedJobName(target.value ?? '');
          }}
          value={userProvidedJobName}>
          <Typography variant="body-small-strong">{t('updateDrawer.updateJobDisplayName')}</Typography>
        </TextField>
      </section>
      <section>
        <Schedule
          isSchedulingFeatureEnabled={isSchedulingFeatureEnabled}
          userEnabledScheduling={userEnabledScheduling}
          onIsScheduledChange={setUserEnabledScheduling}
          onScheduleDateTimeChange={onScheduleDateTimeChange}
          scheduleDateTime={scheduleDateTime}
          isOfflineDeviceSelected={isOfflineDeviceSelected}
        />
      </section>
      {isForceRebootPossible && (
        <section>
          <ForceRebootSwitcher
            updatesRequireRebootCount={numberOfUpdatesRequiringReboot(uniqueUpdates)}
            checked={forceRebootEnabled}
            onChange={setForceRebootEnabled}
          />
          {forceRebootEnabled && (
            <ForceRebootDelayPicker value={forceRebootDelayOption} onChange={setForceRebootDelayOption} />
          )}
        </section>
      )}
    </Drawer>
  );
}
