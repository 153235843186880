import {
  ChevronLeftFilledIcon,
  ChevronRightIcon,
  DoubleChevronLeftFilledIcon,
  DoubleChevronRightFilledIcon
} from '@getgo/chameleon-icons/react';
import {
  DataTableActionsV2,
  DataTableCellV2,
  DataTableHeaderCellV2,
  DataTablePaginationV2,
  DataTableRowV2,
  IconButton,
  Option,
  Select,
  Typography,
  DataTableV2,
  DataTableGroupRowV2,
  SearchField,
  Checkbox
} from '@getgo/chameleon-web-react-wrapper/register';
import {
  Cell,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getGroupedRowModel,
  GroupingState,
  Header,
  OnChangeFn,
  Renderable,
  RowData,
  useReactTable,
  getExpandedRowModel,
  VisibilityState,
  Row
} from '@tanstack/react-table';
import { TableOptions, ExpandedState } from '@tanstack/table-core';
import { ChangeEvent, ReactElement, Fragment, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ChameleonGrid.module.scss';
import clsx from 'clsx';
import { debounce } from '../../utils/debounce';

export type TableGroupFormatter = (group?: string) => string;

export interface ChameleonGridProps<TData>
  extends Omit<TableOptions<TData>, 'getCoreRowModel' | 'getFilteredRowModel' | 'getSortedRowModel'> {
  paginationEnabled?: boolean;
  globalFilterEnabled?: boolean;
  selectionEnabled?: boolean;
  columnVisibility?: VisibilityState;
  tableGroupFormatter?: TableGroupFormatter;
  onRowClicked?: (row: Row<any>) => void;
  searchFieldPlaceholder?: string;
  searchText?: string;
  onGlobalFilterChange?: OnChangeFn<any>;
  renderChips?: () => ReactElement;
  groupBy?: string;
  classes?: {
    row?: string;
  };
}

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    isPlaceholder?: boolean;
  }
}

function ChameleonGrid<TData>({
  manualSorting = true,
  manualPagination = true,
  paginationEnabled = true,
  globalFilterEnabled = true,
  selectionEnabled = true,
  onRowClicked,
  columnVisibility,
  tableGroupFormatter,
  onGlobalFilterChange,
  searchText,
  renderChips,
  searchFieldPlaceholder,
  groupBy,
  state,
  classes,
  ...rest
}: ChameleonGridProps<TData>): ReactElement {
  const { t } = useTranslation();
  const pageSizes = [10, 25, 50, 100];
  const [grouping, setGrouping] = useState<GroupingState>(groupBy ? [groupBy] : []);
  const [expanded, setExpanded] = useState<ExpandedState>(true);

  const handleExpandedGroupChange = (e: any): void => {
    // filtering out table events with empty object on init, breaking expandedState
    if (Object.keys(e).length > 0 || typeof e === 'function') {
      setExpanded(e);
    }
  };
  const table = useReactTable<any>({
    ...rest,
    state: {
      ...state,
      grouping,
      expanded,
      columnVisibility
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination,
    manualSorting,
    enableGrouping: true,
    onGroupingChange: setGrouping,
    onExpandedChange: handleExpandedGroupChange
  });

  const paginationState = table.getState().pagination;
  const allRows = table.getPreFilteredRowModel().rows.length;
  const pageIndex = paginationState?.pageIndex;
  const rowsRangeLeft = pageIndex * paginationState?.pageSize + 1;
  const rowsRangeRight = Math.min(allRows, (pageIndex + 1) * paginationState?.pageSize);

  const counterText = t('grid.counter', {
    rowsRangeLeft,
    rowsRangeRight,
    allRows
  });

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    table.setPageSize(Number(event.target.value));
  };

  const debouncedGlobalFilterChange = useMemo(() => {
    if (onGlobalFilterChange) {
      return debounce((value: string) => {
        onGlobalFilterChange(value);
      }, 1000);
    } else {
      return (value: string) => {
        table.setGlobalFilter(value);
      };
    }
  }, [onGlobalFilterChange, table]);

  const handleGlobalFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    debouncedGlobalFilterChange(value);
  };

  function CheckboxWrapper({ header }: { header: Header<any, any> }): ReactElement {
    return (
      <span className={styles.checkboxWrapper}>{flexRender(header.column.columnDef.header, header.getContext())}</span>
    );
  }

  function renderHeaderCell(header: Header<any, any>): ReactElement | null {
    if (header.id === 'select') {
      return <CheckboxWrapper key={header.id} header={header}></CheckboxWrapper>;
    }
    if (header.isPlaceholder || header.column.columnDef.meta?.isPlaceholder) {
      return null;
    }
    return (
      <DataTableHeaderCellV2
        className={clsx(selectionEnabled && styles.headerTableCellPadding)}
        key={header.id}
        sort={header.column.getIsSorted() ? (header.column.getIsSorted() as 'asc' | 'desc') : undefined}
        onSort={header.column.getToggleSortingHandler()}>
        <GridCell header={header} />
      </DataTableHeaderCellV2>
    );
  }

  function GridCell({ header, cell }: { cell?: Cell<any, any>; header?: Header<any, any> }): ReactElement | null {
    const gridElement = header ?? cell;

    if (!gridElement) {
      return null;
    }

    const { size, minSize, maxSize } = gridElement.column.columnDef;

    return (
      <span
        style={{
          width: size ? `${size}px` : 'auto',
          minWidth: minSize ? `${minSize}px` : undefined,
          maxWidth: maxSize ? `${maxSize}px` : undefined
        }}>
        {flexRender(
          (header ? gridElement.column.columnDef.header : gridElement.column.columnDef.cell) as Renderable<any>,
          gridElement.getContext()
        )}
      </span>
    );
  }

  return (
    <>
      <DataTableActionsV2
        className={styles.actions}
        search={
          globalFilterEnabled && (
            <SearchField
              value={onGlobalFilterChange ? searchText : table.getState().globalFilter}
              labelId={t('grid.search')}
              placeholder={searchFieldPlaceholder ?? t('grid.search')}
              onChange={handleGlobalFilterChange}
            />
          )
        }
        manageSelection={undefined}
        actions={undefined}
      />
      {renderChips && renderChips()}
      <DataTableV2 className={styles.grid}>
        {table.getHeaderGroups().map(headerGroup => (
          <DataTableRowV2 key={headerGroup.id} header>
            {headerGroup.headers.map(renderHeaderCell)}
          </DataTableRowV2>
        ))}
        {table.getRowModel().rows.map(row => (
          <Fragment key={row.id}>
            {row.getCanExpand() ? (
              <DataTableRowV2 key={row.id} selected={row.getIsAllSubRowsSelected()}>
                <td colSpan={row.getVisibleCells().length}>
                  <DataTableGroupRowV2
                    selected={row.getIsAllSubRowsSelected()}
                    state={row.getIsExpanded() ? 'expanded' : 'collapsed'}
                    checkbox={
                      <Checkbox
                        className={clsx(!selectionEnabled && styles.groupCheckbox)}
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onClick={e => {
                          e.stopPropagation();
                          selectionEnabled ? row.getToggleSelectedHandler()(e) : row.getToggleExpandedHandler()();
                        }}>
                        {row.getIsGrouped() && tableGroupFormatter && tableGroupFormatter(row.getValue(grouping[0]))}
                        {' ('}
                        {row.subRows.length}
                        {')'}
                      </Checkbox>
                    }
                    onClick={() => {
                      row.getToggleExpandedHandler()();
                    }}></DataTableGroupRowV2>
                </td>
              </DataTableRowV2>
            ) : (
              <DataTableRowV2
                onClick={() => {
                  onRowClicked ? onRowClicked(row) : undefined;
                }}
                key={row.id}
                className={classes?.row}>
                {row.getVisibleCells().map(cell => (
                  <DataTableCellV2 key={cell.id}>
                    <GridCell cell={cell} />
                  </DataTableCellV2>
                ))}
              </DataTableRowV2>
            )}
          </Fragment>
        ))}
      </DataTableV2>
      {paginationEnabled && (
        <DataTablePaginationV2
          counterText={counterText}
          select={
            <Select inline onChange={handlePageSizeChange}>
              {pageSizes.map(size => (
                <Option selected={table.getState().pagination.pageSize === size} key={size} value={size}>
                  {size}
                </Option>
              ))}
            </Select>
          }
          actions={
            <div>
              <IconButton
                label={t('grid.goToFirstPage')}
                size="small"
                disabled={!table.getCanPreviousPage()}
                onClick={() => table.setPageIndex(0)}>
                <DoubleChevronLeftFilledIcon />
              </IconButton>
              <IconButton
                label={t('grid.goToPreviousPage')}
                size="small"
                disabled={!table.getCanPreviousPage()}
                onClick={() => table.previousPage()}>
                <ChevronLeftFilledIcon />
              </IconButton>
              <Typography variant="caption-medium-strong" color="type-color-secondary">
                {t('grid.page', {
                  currentPage: table.getState().pagination.pageIndex + 1,
                  allPage: table.getPageCount()
                })}
              </Typography>
              <IconButton
                label={t('grid.goToNextPage')}
                size="small"
                disabled={!table.getCanNextPage()}
                onClick={() => table.nextPage()}>
                <ChevronRightIcon />
              </IconButton>
              <IconButton
                label={t('grid.goToLastPage')}
                size="small"
                disabled={!table.getCanNextPage()}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                <DoubleChevronRightFilledIcon />
              </IconButton>
            </div>
          }
        />
      )}
    </>
  );
}

export default ChameleonGrid;
