import WindowsUpdatesService from '../services/WindowsUpdatesService';
import useSwr from 'swr';
import { WindowsUpdatesByDeviceResponse } from '../models/windows-updates-by-device-response';
import { useInjection } from 'inversify-react';

const useDeviceWindowsUpdates = (deviceId: string): { data?: WindowsUpdatesByDeviceResponse; error?: Error } => {
  const windowsUpdatesService = useInjection(WindowsUpdatesService);
  return useSwr([deviceId, 'WindowsUpdatesService', 'getDeviceUpdates'], ([deviceId]) =>
    windowsUpdatesService.getDeviceUpdates(deviceId)
  );
};

export default useDeviceWindowsUpdates;
