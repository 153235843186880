import { FeatureSwitch } from '../models/feature-switch';
import { useEffect, useState } from 'react';
import featureSwitchService from './feature-switch.service';

function useFeatureSwitch(featureSwitch: FeatureSwitch): boolean | undefined {
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    featureSwitchService.isEnabled(featureSwitch).then(enabled => {
      setIsEnabled(enabled);
    });
  }, [featureSwitch]);

  return isEnabled;
}

export default useFeatureSwitch;
