import logger from './logger.service';

class LocalStorageService {
  getItem<T>(key: string): T | null {
    try {
      const item = localStorage.getItem(key);
      return item === null ? null : (JSON.parse(item) as T);
    } catch (e) {
      logger.logError(e as Error);
      return null;
    }
  }

  setItem<T>(key: string, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      logger.logError(e as Error);
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  hasKey(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  clear(): void {
    localStorage.clear();
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
