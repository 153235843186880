import SoftwareInventory from '../models/SoftwareInventory';
import useSwr from 'swr';
import ApplicationService from '../services/ApplicationService';
import { useInjection } from 'inversify-react';

const useDeviceApplicationUpdates = (deviceId: string): { data?: SoftwareInventory; error?: Error } => {
  const applicationService = useInjection(ApplicationService);
  return useSwr([deviceId, 'ApplicationService', 'getDeviceUpdates'], ([deviceId]) =>
    applicationService.getDeviceUpdates(deviceId)
  );
};

export default useDeviceApplicationUpdates;
