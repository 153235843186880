import { ContainerModule } from 'inversify';
import ApplicationService from './ApplicationService';
import WindowsUpdatesService from './WindowsUpdatesService';
import CompanyDeviceUpdatesService from './CompanyDeviceUpdatesService';
import { PatchviewDataService } from '../../patch-view/services/PatchviewDataService';

const UpdatesModule = new ContainerModule(bind => {
  bind(ApplicationService).toSelf().inSingletonScope();
  bind(WindowsUpdatesService).toSelf().inSingletonScope();
  bind(CompanyDeviceUpdatesService).toSelf().inSingletonScope();
  bind(PatchviewDataService).toSelf().inSingletonScope();
});

export { UpdatesModule };
