import { ChangeEvent, ChangeEventHandler, ReactElement, useCallback } from 'react';
import styles from './Schedule.module.scss';
import { Alert, Badge, InputField, Switch, Typography } from '@getgo/chameleon-web-react-wrapper';
import { SwitchComponent, TextFieldComponent } from '@getgo/chameleon-web';
import { useTranslation } from 'react-i18next';
import { dateTimeInputFormat, normalizeDateTime } from '../../../core/utilities/utilities';
import { AlertOutlinedIcon } from '@getgo/chameleon-icons/react';
import { DateTime } from 'luxon';
import updateDrawerStyles from './UpdateDrawer.module.scss';

interface Props {
  isSchedulingFeatureEnabled?: boolean;
  userEnabledScheduling: boolean;
  scheduleDateTime?: string;
  onIsScheduledChange: (isScheduled: boolean) => void;
  onScheduleDateTimeChange: (dateString: string) => void;
  isOfflineDeviceSelected: boolean;
}

function Schedule({
  isSchedulingFeatureEnabled,
  userEnabledScheduling,
  scheduleDateTime,
  onIsScheduledChange,
  onScheduleDateTimeChange,
  isOfflineDeviceSelected
}: Props): ReactElement {
  const { t } = useTranslation();
  const minDate = DateTime.fromJSDate(new Date()).plus({ minutes: 1 }).toJSDate();
  const maxDate = DateTime.fromJSDate(new Date()).plus({ years: 1 }).toJSDate();

  const onScheduleSwitched = useCallback<ChangeEventHandler<SwitchComponent>>(
    ({ target }) => {
      if (!isOfflineDeviceSelected) {
        onIsScheduledChange(target.checked);
      }
    },
    [isOfflineDeviceSelected, onIsScheduledChange]
  );

  const onDateTimeChange = useCallback(
    (e: ChangeEvent<TextFieldComponent>): void => {
      if (!e.target.value) {
        return onScheduleDateTimeChange('');
      }

      const normalizedDateTime = normalizeDateTime(e.target.value);
      onScheduleDateTimeChange(normalizedDateTime);
      // we need to set the input element value, to ensure the date/time is withing the range of the allowed min/max dates
      e.target.value = normalizedDateTime;
    },
    [onScheduleDateTimeChange]
  );

  if (!isSchedulingFeatureEnabled) {
    return (
      <>
        <div className={updateDrawerStyles.switchSectionTitle}>
          <div className={styles.scheduleTitleInnerWrapper}>
            <Typography variant="body-small-strong">{t('updateDrawer.schedule.schedule')}</Typography>
          </div>
          <Badge className={styles.scheduleBadge} variant={'info'}>
            {t('updateDrawer.schedule.comingSoon')}
          </Badge>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <div className={updateDrawerStyles.switchSectionTitle}>
          <Switch
            disabled={isOfflineDeviceSelected}
            checked={isOfflineDeviceSelected || userEnabledScheduling}
            onChange={onScheduleSwitched}>
            <Typography variant="body-small-strong">{t('updateDrawer.schedule.schedule')}</Typography>
          </Switch>
        </div>
        <Typography variant="caption-medium" color="type-color-secondary">
          {t('updateDrawer.schedule.scheduleInfo')}
        </Typography>
      </div>

      {isOfflineDeviceSelected && (
        <Alert variant="warning" icon={<AlertOutlinedIcon />}>
          {t('updateDrawer.schedule.offlineDeviceWarning')}
        </Alert>
      )}

      {(isOfflineDeviceSelected || userEnabledScheduling) && (
        <div>
          <InputField
            labelId="date"
            type={'datetime-local'}
            fullwidth
            value={scheduleDateTime}
            onChange={onDateTimeChange}
            min={DateTime.fromJSDate(minDate).toFormat(dateTimeInputFormat)}
            max={DateTime.fromJSDate(maxDate).toFormat(dateTimeInputFormat)}>
            {t('updateDrawer.schedule.scheduleDateTime')}
          </InputField>

          <Typography variant="caption-medium" color="type-color-secondary">
            {t('updateDrawer.schedule.scheduleDateInfo')}
          </Typography>
        </div>
      )}
    </>
  );
}

export default Schedule;
