import { inject, injectable } from 'inversify';
import {
  WindowsUpdatesByDeviceResponse,
  WindowsUpdatesByDeviceResponseDto
} from '../models/windows-updates-by-device-response';
import { HttpServices } from '../../core/services/http/module';
import type { AxiosInstance } from 'axios';
import { AxiosError } from 'axios';
import ApiError from '../../core/services/http/ApiError';
import { Health } from '../models/health';

@injectable()
export default class WindowsUpdatesService {
  constructor(@inject(HttpServices.FrontendApiAxios) private frontendApi: AxiosInstance) {}

  async getDeviceUpdates(deviceId: string): Promise<WindowsUpdatesByDeviceResponse> {
    const response = await this.frontendApi
      .get<WindowsUpdatesByDeviceResponseDto>(`devices/${deviceId}/updates/available/os/windows`)
      .catch((e: AxiosError) => {
        throw new ApiError(`Could not get OS updates of device ${deviceId}`, e);
      });
    return {
      ...response.data,
      updates: response.data.updates.map(update => ({
        ...update,
        kbArticleIds: update.kbArticleIds.map(kbArticleId => `KB${kbArticleId}`),
        severity: update.severity ?? 'Low'
      })),
      health: Health[response.data.health]
    };
  }
}
