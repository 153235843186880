import { Button, ProgressStep, ProgressStepper, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement, useState, Fragment } from 'react';
import styles from './ScheduleUpdatesWizard.module.scss';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../../core/services/navigation.service';
import { useSnackbar } from '../../../core/components/snackbar/SnackbarProvider';
import logger from '../../../core/services/logger.service';
import {
  ArrowLeftOutlinedIcon,
  ArrowRightOutlinedIcon,
  PlayCircleOutlinedIcon,
  RefreshOutlinedIcon
} from '@getgo/chameleon-icons/react';
import { resetTable, selectSelectedUpdates } from '../../state/windows-updates-selector-slice';
import { useAppDispatch, useAppSelector } from '../../../core/store/root.store';
import { ProgressStepPanel } from '@getgo/chameleon-web-react-wrapper/register';
import ReviewAndInstall from '../../components/review-and-install/ReviewAndInstall';
import DevicesSelector from '../../components/devices-selector/DevicesSelector';
import { selectSelectedDeviceIds, selectSelectedDevices } from '../../state/device-selector.slice';
import { formatSelectedDevicesTranslation } from '../../utils/format-selected-devices-translation';
import { useRef } from 'react';
import UpdateScheduler from '../../../windows-updates/services/UpdateScheduler';
import { InstallWindowsUpdatesRequest } from '../../../windows-updates/models/install-updates-request';
import trackingService from '../../../core/services/tracking/tracking.service';
import { PatchViewDevicesSelectedEvent } from '../../../core/models/UserTrackingEvents';

enum WizardStep {
  SelectUpdates,
  SelectDevices,
  ReviewAndInstall
}

export default function ScheduleUpdatesPage(): ReactElement {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(WizardStep.SelectDevices);

  const dispatch = useAppDispatch();
  const selectedDevices = useAppSelector(selectSelectedDevices);
  const selectedUpdates = useAppSelector(selectSelectedUpdates);
  const { deviceIds } = useAppSelector(selectSelectedDeviceIds);

  if (selectedUpdates.length === 0) {
    navigate({ path: '/patch-view' });
  }

  const scheduleDataRef = useRef<InstallWindowsUpdatesRequest>();

  const onScheduleUpdates = async (): Promise<void> => {
    const scheduleData = scheduleDataRef.current;

    if (!scheduleData) {
      return;
    }

    try {
      await UpdateScheduler.scheduleWindowsUpdate(scheduleData);
      snackbar.show({
        title: t(`scheduleUpdatesPage.snackbar.updateSuccess`)
      });
      navigate({ path: '/patch-view/history' });
    } catch (e) {
      logger.logError(e as Error);
    }
  };

  const handleBackClick = (): void => {
    if (currentIndex === WizardStep.SelectDevices) {
      navigate({ path: '/patch-view' });
      return;
    }
    setCurrentIndex(prev => prev - 1);
  };

  const handleNextClick = (): void => {
    if (currentIndex === WizardStep.SelectDevices) {
      try {
        trackingService.trackUserEvent(new PatchViewDevicesSelectedEvent(selectedDevices.length));
      } catch (error) {
        logger.logError(error as Error | string);
      }
    }
    setCurrentIndex(prev => prev + 1);
  };

  const handleStartOverClick = (): void => {
    dispatch(resetTable());
    navigate({ path: '/patch-view' });
  };

  const isStepSelected = (arr: WizardStep[]): boolean => {
    return arr.includes(currentIndex);
  };

  const handleStepChange = (e: Event): void => {
    const activeStepIndex = +(e.target as EventTarget & { activeStep: string }).activeStep;

    if (currentIndex !== activeStepIndex) {
      setCurrentIndex(activeStepIndex);
    }
  };

  return (
    <>
      <Typography className={styles.title} variant="heading-medium" tag="h1">
        {t(`scheduleUpdatesPage.title`)}
      </Typography>
      <div>
        <ProgressStepper className={styles.progressStepper} activeStep={currentIndex} onStepChange={handleStepChange}>
          <ProgressStep
            badgeValue="1"
            showTooltip={false}
            variant="success"
            description={t('scheduleUpdatesPage.progressStepper.selectUpdatesDescription', {
              number: selectedUpdates.length
            })}>
            {t(`scheduleUpdatesPage.progressStepper.selectUpdates`)}
          </ProgressStep>
          <ProgressStep
            badgeValue="2"
            showTooltip={false}
            selected={isStepSelected([WizardStep.SelectDevices])}
            variant={isStepSelected([WizardStep.SelectDevices]) ? 'neutral' : 'success'}
            description={formatSelectedDevicesTranslation(deviceIds)}>
            {t(`scheduleUpdatesPage.progressStepper.selectDevices`)}
          </ProgressStep>
          <ProgressStep showTooltip={false} badgeValue="3" selected={isStepSelected([WizardStep.ReviewAndInstall])}>
            {t(`scheduleUpdatesPage.progressStepper.reviewAndInstall`)}
          </ProgressStep>
          <ProgressStepPanel></ProgressStepPanel>
          <ProgressStepPanel>
            <DevicesSelector selectedUpdates={selectedUpdates}></DevicesSelector>
          </ProgressStepPanel>
          <ProgressStepPanel>
            <ReviewAndInstall
              selectedDevices={selectedDevices}
              selectedUpdates={selectedUpdates}
              scheduleDataRef={scheduleDataRef}></ReviewAndInstall>
          </ProgressStepPanel>
        </ProgressStepper>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsLeft}>
          <Button variant="tertiary" size="large" leadingIcon={<RefreshOutlinedIcon />} onClick={handleStartOverClick}>
            {t('scheduleUpdatesPage.actions.startOver')}
          </Button>
        </div>
        <div className={styles.actionsRight}>
          <Button variant="neutral" size="large" leadingIcon={<ArrowLeftOutlinedIcon />} onClick={handleBackClick}>
            {t('scheduleUpdatesPage.actions.back')}
          </Button>
          {currentIndex === WizardStep.ReviewAndInstall ? (
            <Button
              size="large"
              isLoading={false}
              onClick={onScheduleUpdates}
              trailingIcon={<PlayCircleOutlinedIcon />}
              type="submit">
              {t(`scheduleUpdatesPage.actions.scheduleUpdates`)}
            </Button>
          ) : (
            <Button size="large" trailingIcon={<ArrowRightOutlinedIcon />} onClick={handleNextClick}>
              {t('scheduleUpdatesPage.actions.nextStep')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
