import { ICellRendererParams } from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';
import { WindowsUpdate } from '../../../models/windows-update';
import { ReactComponent as SeverityCriticalIcon } from './severity-critical.svg';
import { ReactComponent as SeverityImportantIcon } from './severity-important.svg';
import { ReactComponent as SeverityLowIcon } from './severity-low.svg';
import { ReactComponent as SeverityModerateIcon } from './severity-moderate.svg';
import styles from './SeverityCellRenderer.module.scss';

// NOTE: https://learn.microsoft.com/en-us/previous-versions/windows/desktop/ms752745(v=vs.85)
type Severity = 'Important' | 'Moderate' | 'Low' | 'Critical' | 'Unspecified';

const severityIconMap: Record<Severity, ReactElement> = {
  Critical: <SeverityCriticalIcon className={styles.critical} />,
  Important: <SeverityImportantIcon className={styles.important} />,
  Moderate: <SeverityModerateIcon className={styles.moderate} />,
  Low: <SeverityLowIcon className={styles.low} />,
  Unspecified: <SeverityLowIcon className={styles.low} />
};

const severityTranslationMap: Record<Severity, TFuncKey> = {
  Critical: 'severity.critical',
  Important: 'severity.important',
  Moderate: 'severity.moderate',
  Low: 'severity.low',
  Unspecified: 'severity.low'
};

export default function SeverityCellRenderer({ value }: ICellRendererParams<WindowsUpdate, Severity>): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.cell}>
      <>
        {severityIconMap[value]}
        {t(severityTranslationMap[value])}
      </>
    </div>
  );
}
