import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../core/store/root.store';
import { UpdateRow } from '../types/frontend/update-row';

export interface WindowsUpdatesSelectorState {
  selectedUpdates: UpdateRow[];
}

const initialState: WindowsUpdatesSelectorState = {
  selectedUpdates: []
};

export const windowsUpdatesSlice = createSlice({
  name: 'windowsUpdates',
  initialState,
  reducers: {
    setSelectedUpdates: (state, action: PayloadAction<UpdateRow[]>) => {
      state.selectedUpdates = action.payload;
    },
    resetTable: () => initialState
  }
});

export const selectSelectedUpdates = (state: RootState): UpdateRow[] => state.windowsUpdates.selectedUpdates;

export const { setSelectedUpdates, resetTable } = windowsUpdatesSlice.actions;

export const { reducer: windowsUpdatesReducer } = windowsUpdatesSlice;
