import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '../../../core/components/grid/Grid';
import { ColDef } from '@ag-grid-community/core/dist/cjs/es5/entities/colDef';
import { useDialog } from '../../../core/components/dialogs/DialogProvider';
import useFeatureSwitch from '../../../core/feature-switch/use-feature-switch.hook';
import { FeatureSwitch } from '../../../core/models/feature-switch';
import { WindowsUpdate } from '../../models/windows-update';
import { Health } from '../../models/health';
import { AgGridReact } from '@ag-grid-community/react';
import {
  generalColumnDefs,
  getWindowsUpdatesColumnDefsAsArray
} from '../../../core/components/grid/column-definitions';
import {
  FirstDataRenderedEvent,
  GetRowIdParams,
  ICellRendererParams,
  SelectionChangedEvent
} from '@ag-grid-community/core';
import GridActions from '../../../core/components/grid/GridActions';
import Tooltip from '../../../core/components/tooltips/Tooltip';
import { Menu, MenuItem, MenuSection } from '@getgo/chameleon-web-react-wrapper';
import GridMoreActions from '../../../core/components/grid/GridMoreActions';
import DeviceDetailsEmptyState, { DeviceDetailsEmptyStateProps } from './DeviceDetailsEmptyState';
import LoadingOverlay from '../../../core/components/grid/loading-overlay/LoadingOverlay';
import styles from './DeviceDetails.module.scss';
import UpdateGridProps from './UpdateGridProps';
import ExternalLink from '../../../core/components/ExternalLink';
import InstallUpdateButton from './InstallUpdateButton';
import useDeviceWindowsUpdates from '../../hooks/useDeviceWindowsUpdates';
import useDisabledUpdateTooltipText from '../../hooks/useDisabledUpdateTooltipText';
import useUpdateInstallCapabilities from '../../hooks/useUpdateInstallCapabilities';
import UpdateInstallGuard from '../../hooks/UpdateInstallGuard';
import { WindowsPatchFilter, WindowsUpdatePatchesFilteredEvent } from '../../../core/models/UserTrackingEvents';
import DeviceInventoryUpdateDrawer from '../UpdateDrawer/DeviceInventoryUpdateDrawer';
import {
  decodeCopilotUrlQueryStringPayload,
  getSelectedWindowsUpdateIdsFromPayload
} from '../../utilities/device-details-utilities';
import GridFilterIds from '../../models/GridFilterIds';

function DeviceWindowsUpdatesGrid(
  props: {
    isVisible: boolean;
    initialFilter: GridFilterIds;
    onFilterChanged: (currentFilter: GridFilterIds) => void;
  } & UpdateGridProps
): ReactElement {
  const {
    isVisible,
    initialFilter,
    onFilterChanged,
    deviceId,
    device,
    inProgressUpdates,
    addUpdatesInProgress,
    isOnlineStatusEnabled,
    setInstallUpdatesState,
    setNumberOfUpdatesInProgress
  } = props;

  const deviceUpdates = useDeviceWindowsUpdates(deviceId);
  const { updates, health, errorCode } = deviceUpdates.data ?? {
    device: undefined,
    updates: [],
    health: undefined,
    errorCode: null
  };

  const updateInstallCapabilities = useUpdateInstallCapabilities();
  const { t } = useTranslation();
  const dialog = useDialog();

  const [selectedUpdates, setSelectedUpdates] = useState<WindowsUpdate[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [installRequestPending, setInstallRequestPending] = useState(false);

  const loading = !deviceUpdates.data;
  const canUserInstall = updateInstallCapabilities.deviceCanInstallUpdates(props) && !loading;
  const canUserInstallRef = useRef(canUserInstall);
  canUserInstallRef.current = canUserInstall;

  const isSchedulingFeatureEnabled = useFeatureSwitch(FeatureSwitch.Scheduling) ?? true;

  const isEmptyState =
    typeof health !== 'undefined' && [Health.Unknown, Health.Error, Health.UpToDate].includes(health);
  const gridReady = !loading && !isEmptyState;
  const gridRef = useRef<AgGridReact>(null);

  const isUpdateInProgress = useCallback(
    (data?: WindowsUpdate): boolean => {
      return !!data && inProgressUpdates.some(update => update.id === data?.updateId);
    },
    [inProgressUpdates]
  );

  const updatesData = useMemo(() => {
    return updates?.map(update => ({ ...update, inProgress: isUpdateInProgress(update) })) ?? [];
  }, [isUpdateInProgress, updates]);

  const numberOfUpdatesInProgress = useMemo(
    () => updatesData.filter(update => update.inProgress).length,
    [updatesData]
  );

  const isInstallAllBtnDisabled =
    !isOnlineStatusEnabled(device?.onlineStatus) || !updates.length || updates.length === numberOfUpdatesInProgress;

  const isRowSelectableRef = useRef(
    (rowNode: { data?: WindowsUpdate }): boolean => canUserInstallRef.current && !rowNode.data?.inProgress
  );

  // TODO is this still needed?
  /*useEffect(() => {
    gridRef.current?.api?.redrawRows(); // sync Spinner
  }, [updatesData]);*/

  const disabledUpdateTooltipText = useDisabledUpdateTooltipText();
  const deviceWindowsUpdatesColumnDefinition: ColDef<WindowsUpdate>[] = [
    {
      ...generalColumnDefs.select,
      tooltipValueGetter: params => disabledUpdateTooltipText(device, params.data?.inProgress)
    },
    ...getWindowsUpdatesColumnDefsAsArray(),
    {
      ...generalColumnDefs.actions,
      cellRenderer: (params: ICellRendererParams<WindowsUpdate>) => (
        <GridActions>
          <Tooltip
            hidden={canUserInstall && !params.data?.inProgress}
            trigger={
              <InstallUpdateButton
                inProgress={params.data?.inProgress}
                installRequestPending={installRequestPending}
                onInstallUpdateClick={() => handleInstallUpdateClick(params)}
              />
            }>
            {disabledUpdateTooltipText(device, params.data?.inProgress)}
          </Tooltip>
          <GridMoreActions gridCell={params.eGridCell}>
            <Menu>
              <MenuSection>{t('deviceDetails.grid.windows.moreActions.title')}</MenuSection>
              <MenuItem onClick={() => handleCreateTicket()}>
                {t('deviceDetails.grid.windows.moreActions.createTicket')}
              </MenuItem>
            </Menu>
          </GridMoreActions>
        </GridActions>
      )
    }
  ];

  const handleCreateTicket = (): void => {
    if (!device) {
      return;
    }
    dialog.showCreateTicketDialog(device.id);
  };

  const clearSelection = useCallback((): void => {
    gridRef.current?.api?.deselectAll();
  }, []);

  const updateInstallGuard = UpdateInstallGuard.useForDevice();
  const handleInstallAllUpdatesClick = useCallback((): void => {
    if (updateInstallGuard()) {
      return;
    }
    gridRef.current?.api.selectAll();
  }, [updateInstallGuard, gridRef]);

  const handleInstallUpdateClick = (params: ICellRendererParams<WindowsUpdate>): void => {
    if (updateInstallGuard()) {
      return;
    }
    params.api.deselectAll();
    params.node.setSelected(true);
  };

  const handleSelectionChanged = useCallback((event: SelectionChangedEvent<WindowsUpdate>) => {
    const rows = event.api.getSelectedRows();
    setSelectedUpdates(rows);
  }, []);
  useEffect(() => setShowDrawer(selectedUpdates.length > 0), [selectedUpdates, setShowDrawer]);

  useEffect(() => {
    if (isVisible) {
      setInstallUpdatesState({
        canUserInstall,
        handleInstallAllUpdatesClick,
        isInstallAllBtnDisabled,
        installRequestPending,
        buttonText: t('deviceDetails.actions.runAllOSUpdates')
      });
      setNumberOfUpdatesInProgress(numberOfUpdatesInProgress);
    } else {
      clearSelection();
    }
  }, [
    setInstallUpdatesState,
    canUserInstall,
    handleInstallAllUpdatesClick,
    isInstallAllBtnDisabled,
    installRequestPending,
    isSchedulingFeatureEnabled,
    t,
    isVisible,
    setNumberOfUpdatesInProgress,
    numberOfUpdatesInProgress,
    clearSelection
  ]);

  const [searchParams] = useSearchParams();

  const onFirstDataRendered = useCallback((event: FirstDataRenderedEvent) => {
    const encodedCopilotActions = searchParams.get('actions');
    if (encodedCopilotActions) {
      const decodedSearchParams = decodeCopilotUrlQueryStringPayload(encodedCopilotActions);
      const ids = getSelectedWindowsUpdateIdsFromPayload(decodedSearchParams);
      event.api.forEachNode(node => {
        const rowData = node.data;
        if (ids.includes(rowData.updateId)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }, []);

  return (
    <>
      {isEmptyState && (
        <DeviceDetailsEmptyState health={health as DeviceDetailsEmptyStateProps['health']} errorCode={errorCode}>
          {[Health.Unknown, Health.Error].includes(health) && (
            <ExternalLink to="https://support.goto.com/resolve/help/working-with-windows-updates-in-goto-resolve">
              {t('deviceDetails.emptyState.learnMore')}
            </ExternalLink>
          )}
        </DeviceDetailsEmptyState>
      )}
      {loading && <LoadingOverlay className={styles.loading} />}

      {gridReady && (
        <Grid
          //onGridReady={(event: GridReadyEvent) => event.api.sizeColumnsToFit()}
          toolbarProps={{
            searchFieldPlaceholder: t('deviceDetails.grid.windows.toolbar.searchFieldPlaceholder'),
            filters: [
              {
                label: t('deviceDetails.grid.windows.toolbar.actions.allUpdates'),
                trackingEvent: new WindowsUpdatePatchesFilteredEvent(WindowsPatchFilter.AllUpdates),
                filterModel: null,
                filterId: GridFilterIds.ALL
              },
              {
                // NOTE: a custom text matcher is used in column-definitions.ts for filtering severity
                label: t('deviceDetails.grid.windows.toolbar.actions.criticalUpdates'),
                trackingEvent: new WindowsUpdatePatchesFilteredEvent(WindowsPatchFilter.ImportantAndCritical),
                filterModel: {
                  severity: {
                    filterType: 'text',
                    type: 'contains',
                    filter: 'important'
                  }
                },
                filterId: GridFilterIds.CRITICAL_UPDATES
              }
            ],
            initialFilter: initialFilter,
            onFilterChanged: onFilterChanged
          }}
          ref={gridRef}
          columnDefs={deviceWindowsUpdatesColumnDefinition}
          getRowId={(params: GetRowIdParams<WindowsUpdate>) => params.data.updateId}
          onSelectionChanged={handleSelectionChanged}
          rowData={updatesData}
          isRowSelectable={isRowSelectableRef.current}
          onFirstDataRendered={onFirstDataRendered}
        />
      )}
      {showDrawer && (
        <DeviceInventoryUpdateDrawer
          onClose={clearSelection}
          selectedDeviceUpdates={
            device
              ? [
                  {
                    device: device,
                    updates: selectedUpdates.map(update => ({
                      type: 'windows-update',
                      id: update.updateId,
                      isOptional: !update.isMandatory,
                      requiresReboot: update.rebootRequired,
                      makesDeviceVulnerable: update.severity == 'Critical' || update.severity == 'Important'
                    }))
                  }
                ]
              : []
          }
          isInstallationPending={installRequestPending}
          setInstallationPending={setInstallRequestPending}
          addUpdatesInProgress={addUpdatesInProgress}
        />
      )}
    </>
  );
}

export default DeviceWindowsUpdatesGrid;
