import { Guid } from '../../core/models/guid';
import { HostRunStatus, HostRunStatusCount } from '@goto/remote-execution';
import { z } from 'zod';

const HostRunStatusSchema = z.literal(0).or(z.literal(10)).or(z.literal(20)).or(z.literal(90)).or(z.literal(100));
export const UpdateJobItemSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  scheduledAt: z.string(),
  isScheduled: z.boolean(),
  finishedAt: z.string().optional().nullable(),
  status: HostRunStatusSchema,
  deviceCount: z.number(),
  hostRunStatusCounts: z.array(
    z.object({
      status: HostRunStatusSchema,
      count: z.number()
    })
  ),
  osUpdateInfo: z
    .object({
      mandatoryUpdateCount: z.number(),
      optionalUpdateCount: z.number(),
      forceReboot: z.boolean(),
      forceRebootMaxDelaysCount: z.number().nullable()
    })
    .nullable(),
  appUpdateInfo: z
    .object({
      count: z.number()
    })
    .nullable()
});

export interface UpdateJobItemDto1 {
  id: Guid;
  displayName: string;
  // TODO: make this filed required when scheduling related changes are merged to PM backend master
  scheduledAt?: string;
  isScheduled: boolean;
  finishedAt?: string | null;
  status: HostRunStatus;
  mandatoryUpdateCount: number;
  optionalUpdateCount: number;
  deviceCount: number;
  hostRunStatusCounts: HostRunStatusCount[];
  appUpdateInfo: AppUpdateInfo;
}

export interface UpdateJobItemDto {
  id: Guid;
  displayName: string;
  scheduledAt: string;
  isScheduled: boolean;
  finishedAt?: string | null;
  status: HostRunStatus;
  deviceCount: number;
  hostRunStatusCounts: HostRunStatusCount[];
  osUpdateInfo: OsUpdateInfo | null;
  appUpdateInfo: AppUpdateInfo | null;
}

export const jobStatuses = ['running', 'scheduled', 'completed'] as const;

export interface UpdateJobItem extends Omit<UpdateJobItemDto, 'scheduledAt' | 'finishedAt'> {
  scheduledAt: Date;
  finishedAt?: Date;
  updateCount: number;
  jobStatus: (typeof jobStatuses)[number];
}

export interface OsUpdateInfo {
  mandatoryUpdateCount: number;
  optionalUpdateCount: number;
  forceReboot: boolean;
  forceRebootMaxDelaysCount: number | null;
}
export interface AppUpdateInfo {
  count: number;
}
