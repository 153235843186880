// NOTE: https://learn.microsoft.com/en-us/previous-versions/windows/desktop/ms752745(v=vs.85)
enum WindowsUpdateSeverity {
  Critical = 'Critical',
  Important = 'Important',
  Moderate = 'Moderate',
  Low = 'Low',
  Unspecified = 'Unspecified'
}

export default WindowsUpdateSeverity;
