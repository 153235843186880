import UpdateStatus from '../models/UpdateStatus';
import OnlineStatus from '../models/OnlineStatus';
import useFeatureSwitch from '../../core/feature-switch/use-feature-switch.hook';
import { FeatureSwitch } from '../../core/models/feature-switch';
import useIsCapabilityEnabled from './useIsCapabilityEnabled';
import { Capability } from '../../core/models/user-capability';
import { useMemo } from 'react';

interface DeviceHolder {
  device?: {
    onlineStatus: OnlineStatus;
  };
}

interface UpdateStatusHolder {
  updateStatus?: UpdateStatus;
}

class DeviceStatusCapabilites {
  constructor(
    private isSchedulingFeatureEnabled: boolean,
    private userIsPatchManagementCapable: boolean
  ) {}

  hasUpdatesToInstall(updateStatusHolder?: UpdateStatusHolder): boolean {
    if (!updateStatusHolder?.updateStatus) {
      return false;
    }
    return [UpdateStatus.Vulnerable, UpdateStatus.UpdatesAvailable, UpdateStatus.UpToDateWithOptionalUpdates].includes(
      updateStatusHolder.updateStatus
    );
  }

  deviceCanReceiveUpdates(deviceHolder?: DeviceHolder): boolean {
    if (!deviceHolder?.device) {
      return false;
    }
    const deviceIsOnline = [OnlineStatus.Online, OnlineStatus.InSession].includes(deviceHolder.device.onlineStatus);
    return deviceIsOnline || this.isSchedulingFeatureEnabled;
  }

  deviceCanInstallUpdates(deviceHolder?: DeviceHolder): boolean {
    if (!deviceHolder?.device) {
      return false;
    }
    return this.deviceCanReceiveUpdates(deviceHolder) && this.userIsPatchManagementCapable;
  }

  canInstallUpdates(updateRow?: DeviceHolder & UpdateStatusHolder): boolean {
    if (!updateRow) {
      return false;
    }
    return this.hasUpdatesToInstall(updateRow) && this.deviceCanInstallUpdates(updateRow);
  }
}

export default function useUpdateInstallCapabilities(): DeviceStatusCapabilites {
  const isSchedulingFeatureEnabled = useFeatureSwitch(FeatureSwitch.Scheduling) ?? false;
  const userIsPatchManagementCapable = useIsCapabilityEnabled(Capability.PatchManagement);

  return useMemo(
    () => new DeviceStatusCapabilites(isSchedulingFeatureEnabled, userIsPatchManagementCapable),
    [isSchedulingFeatureEnabled, userIsPatchManagementCapable]
  );
}
