import { ReactElement, useEffect, useState } from 'react';
import { Button, Chip } from '@getgo/chameleon-web-react-wrapper';
import styles from './WindowsUpdatesSelector.module.scss';
import { navigate } from '../../../core/services/navigation.service';
import WindowsUpdatesGrid from './windows-updates-grid/WindowsUpdatesGrid';
import usePatchviewDataService from '../../services/usePatchviewDataService';
import { PaginationState, RowSelectionState, SortingState, Updater } from '@tanstack/react-table';
import useSwr from 'swr';
import { PatchviewDataService } from '../../services/PatchviewDataService';
import { useAppDispatch } from '../../../core/store/root.store';
import { setSelectedUpdates } from '../../state/windows-updates-selector-slice';
import { t } from '../../../i18n/i18n';
import { CircularProgress } from '@getgo/chameleon-web-react-wrapper';
import { UpdateRow } from '../../types/frontend/update-row';
import { UpToDate } from '../up-to-date-status/UpToDateStatus';
import { ErrorFallback } from '../error-fallback/ErrorFallback';
import logger from '../../../core/services/logger.service';
import { TrackedButton } from '../../../core/components/tracking/TrackedComponents';
import { PatchViewLoadedEvent, PatchViewUpdatesSelectedEvent } from '../../../core/models/UserTrackingEvents';
import trackingService from '../../../core/services/tracking/tracking.service';

function WindowsUpdatesSelector(): ReactElement {
  const { patchviewDataService, isPatchviewDataServiceReady } = usePatchviewDataService();
  const dispatch = useAppDispatch();
  const [showCriticalOnly, setShowCriticalOnly] = useState(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    try {
      trackingService.trackUserEvent(new PatchViewLoadedEvent());
    } catch (error) {
      logger.logError(error as Error | string);
    }
  }, []);

  const fetchAvailableUpdates = async (
    patchviewDataService: PatchviewDataService
  ): Promise<{ getAvailableUpdates: UpdateRow[] }> => {
    if (!patchviewDataService) {
      throw new Error('PatchviewDataService is not initialized');
    }
    return patchviewDataService.getAvailableUpdates(pagination, sorting, showCriticalOnly, searchText);
  };

  const {
    data: updates,
    error,
    isLoading
  } = useSwr(
    isPatchviewDataServiceReady
      ? ['getAvailableUpdates', pagination.pageIndex, pagination.pageSize, sorting, showCriticalOnly, searchText]
      : null,
    () => fetchAvailableUpdates(patchviewDataService),
    { shouldRetryOnError: false, use: [] }
  );

  useEffect(() => {
    const indices = Object.entries(rowSelection)
      .filter(value => value[1])
      .map(value => +value[0]);

    const selectedUpdates = updates?.getAvailableUpdates.filter((_, index) => indices.includes(index)) ?? [];
    dispatch(setSelectedUpdates(selectedUpdates));
  }, [updates, rowSelection, dispatch]);

  const handlePaginationChange = (updaterOrValue: Updater<PaginationState>): void => {
    if (typeof updaterOrValue === 'function') {
      setPagination(prev => updaterOrValue(prev));
    } else {
      setPagination(updaterOrValue);
    }
  };

  const handleRowSelectionChange = (updaterOrValue: Updater<RowSelectionState>): void => {
    if (typeof updaterOrValue === 'function') {
      setRowSelection(updaterOrValue(rowSelection));
    } else {
      setRowSelection(updaterOrValue);
    }
  };

  const handleSortingChange = (updaterOrValue: Updater<SortingState>): void => {
    if (typeof updaterOrValue === 'function') {
      setSorting(prev => updaterOrValue(prev));
    } else {
      setSorting(updaterOrValue);
    }
  };

  const renderChips = (): ReactElement => {
    return (
      <div className={styles.chipsContainer}>
        <Chip size="small" active={showCriticalOnly === false} onClick={() => setShowCriticalOnly(false)}>
          All updates
        </Chip>
        <Chip size="small" active={showCriticalOnly === true} onClick={() => setShowCriticalOnly(true)}>
          Only critical updates
        </Chip>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress></CircularProgress>
      </div>
    );
  }

  if (!error && !isLoading && !updates?.getAvailableUpdates.length && !showCriticalOnly && !searchText) {
    return <UpToDate></UpToDate>;
  }

  if (error || !updates) {
    logger.logError(error);
    return <ErrorFallback></ErrorFallback>;
  }

  return (
    <>
      <TrackedButton
        trackingEvent={new PatchViewUpdatesSelectedEvent(Object.values(rowSelection).length)}
        className={styles.updateButton}
        variant="secondary"
        disabled={!Object.values(rowSelection).length}
        onClick={() => {
          navigate({ path: '/schedule' });
        }}>
        {t('windowsUpdatesSelector.installUpdates')}
      </TrackedButton>
      <WindowsUpdatesGrid
        data={updates.getAvailableUpdates}
        onPaginationChange={handlePaginationChange}
        pagination={pagination}
        onRowSelectionChange={handleRowSelectionChange}
        rowSelection={rowSelection}
        onSortingChange={handleSortingChange}
        sorting={sorting}
        globalFilterEnabled={true}
        onGlobalFilterChange={(searchString: string) => {
          setSearchText(searchString);
        }}
        searchText={searchText}
        renderChips={renderChips}
        state={{ pagination, rowSelection, sorting }}></WindowsUpdatesGrid>
    </>
  );
}

export default WindowsUpdatesSelector;
