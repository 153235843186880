import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, Fragment } from 'react';
import styles from './JobHistoryGrid.module.scss';
import { Button, CircularProgress } from '@getgo/chameleon-web-react-wrapper';
import { UpdateJobItem } from '../../../windows-updates/models/update-job-item';
import { useTranslation } from 'react-i18next';
import { useUpdateJobs } from '../../../windows-updates/hooks/use-update-jobs-hook';
import { MenuItemClickEvent } from '@getgo/chameleon-web';
import { navigate } from '../../../core/services/navigation.service';
import clsx from 'clsx';
import ChameleonGrid, { TableGroupFormatter } from '../../core/chameleon-grid/ChameleonGrid';
import JobProgressCellRenderer from '../../core/chameleon-cell-renderers/JobProgressCellRenderer';
import JobStatusCellRenderer from '../../core/chameleon-cell-renderers/JobStatusCellRenderer';
import JobUpdateCountCellRenderer from '../../core/chameleon-cell-renderers/JobUpdatesCountCellRenderer';
import { CellText } from '../../core/chameleon-cell-renderers/CellText';
import JobDateScheduledCellRenderer from '../../core/chameleon-cell-renderers/JobDateScheduledCellRenderer';
import DateTimeCellRenderer from '../../core/chameleon-cell-renderers/DateTimeCellRenderer';
const columnHelper = createColumnHelper<UpdateJobItem>();

function JobHistoryGrid({ data = [] }: { data?: UpdateJobItem[] }): ReactElement {
  const { t } = useTranslation();

  const { loading, jobs } = useUpdateJobs();

  const seeDetails = (event: React.MouseEvent | MenuItemClickEvent, data: UpdateJobItem | undefined): void => {
    event.preventDefault();
    navigate({ path: '/updates/:updateJobId', params: { updateJobId: `${data?.id}` } });
  };

  const columns = [
    columnHelper.accessor('jobStatus', {
      header: () => t('jobHistoryGrid.headers.status')
    }),
    columnHelper.accessor('hostRunStatusCounts', {
      header: () => t('jobHistoryGrid.headers.progress'),
      cell: props => JobProgressCellRenderer(props.getValue())
    }),
    columnHelper.accessor('displayName', {
      header: () => t('jobHistoryGrid.headers.name'),
      cell: props => <CellText>{props.getValue()}</CellText>
    }),
    columnHelper.accessor('status', {
      header: () => t('jobHistoryGrid.headers.status'),
      cell: props => JobStatusCellRenderer(props.getValue())
    }),
    columnHelper.accessor('updateCount', {
      header: () => t('jobHistoryGrid.headers.updates'),
      cell: props =>
        JobUpdateCountCellRenderer({
          osMandatoryUpdateCount: props.row.original?.osUpdateInfo?.mandatoryUpdateCount ?? 0,
          osOptionalUpdateCount: props.row.original?.osUpdateInfo?.optionalUpdateCount ?? 0,
          appUpdateCount: props.row.original?.appUpdateInfo?.count ?? 0
        })
    }),
    columnHelper.accessor('deviceCount', {
      header: () => t('jobHistoryGrid.headers.devices'),
      cell: props => <CellText>{props.getValue()}</CellText>
    }),
    columnHelper.accessor('scheduledAt', {
      header: () => t('jobHistoryGrid.headers.scheduledFor'),
      cell: props => JobDateScheduledCellRenderer(props.getValue())
    }),
    columnHelper.accessor('finishedAt', {
      header: () => t('jobHistoryGrid.headers.finishedAt'),
      cell: props => DateTimeCellRenderer(props.getValue()),
      minSize: 70
    }),
    columnHelper.display({
      id: 'actions',
      minSize: 0,
      maxSize: 0,
      meta: { isPlaceholder: true },
      cell: cellContext => (
        <div className={clsx(styles.actions)}>
          <div className={clsx(styles.actionItemsWrapper)}>
            <div className={clsx(styles.actionItems)}>
              <Button variant="neutral" size="large" onClick={e => seeDetails(e, cellContext.row.original)}>
                {t('jobHistoryGrid.seeDetails')}
              </Button>
            </div>
          </div>
        </div>
      )
    })
  ];

  const tableGroupFormatter: TableGroupFormatter = grouping => {
    function isSpecificGroup(group: string): group is 'completed' | 'running' | 'scheduled' {
      return ['completed', 'running', 'scheduled'].includes(group);
    }

    if (grouping && isSpecificGroup(grouping)) {
      return t(`jobHistoryGrid.groups.${grouping}`);
    } else {
      return 'Unknown';
    }
  };

  if (!data && loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress></CircularProgress>
      </div>
    );
  }

  return (
    <Fragment>
      <ChameleonGrid
        classes={{ row: styles.row }}
        defaultColumn={{ size: undefined }}
        columns={columns}
        data={data.length ? data : (jobs as any)}
        tableGroupFormatter={tableGroupFormatter}
        manualSorting={false}
        manualPagination={false}
        manualFiltering={false}
        selectionEnabled={false}
        globalFilterEnabled={true}
        groupBy={'jobStatus'}
        columnVisibility={{ jobStatus: false }}
        initialState={{
          sorting: [
            { id: 'jobStatus', desc: true },
            { id: 'finishedAt', desc: true }
          ]
        }}
      />
    </Fragment>
  );
}

export default JobHistoryGrid;
