import { ReactElement, useState, useEffect } from 'react';
import styles from './UpToDateStatus.module.scss';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { t } from '../../../i18n/i18n';

const emojiDescriptions = ['🌞', '🐝', '🍏', '🚜', '👍', '🐙', '🐴', '🍠', '🍃', '🎉', '👌', '🎈'];

export function UpToDate(): ReactElement {
  const [selectedEmoji, setSelectedEmoji] = useState<string>('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * emojiDescriptions.length);
    setSelectedEmoji(emojiDescriptions[randomIndex]);
  }, []);

  const emojiIndex = emojiDescriptions.indexOf(selectedEmoji) + 1;

  return (
    <div className={styles.container}>
      {selectedEmoji && (
        <>
          <Typography variant="avatar-xxxlarge">{selectedEmoji}</Typography>
          <Typography tag="h1" variant="avatar-xlarge" className={styles.description}>
            {t(`upToDateStatus.descriptions.${emojiIndex}` as 'upToDateStatus.descriptions.1')}
          </Typography>
          <Typography className={styles.subtext} variant="body-medium" color="type-color-disabled">
            {t(`upToDateStatus.subtext.${emojiIndex}` as 'upToDateStatus.subtext.1')}
          </Typography>
        </>
      )}
    </div>
  );
}
