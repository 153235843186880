import { ReactElement } from 'react';
import styles from './WindowsUpdateDescriptionRenderer.module.scss';
import ExternalLink from '../../../core/components/ExternalLink';
import { useTranslation } from 'react-i18next';
import { UpdateRow } from '../../types/frontend/update-row';
import { Typography } from '@getgo/chameleon-web-react-wrapper/register';

function WindowsUpdateDescriptionRenderer(title: string, data: UpdateRow): ReactElement {
  function VirusDefinitionUpdateLink({ version }: { version: string }): ReactElement {
    const {
      i18n: { language }
    } = useTranslation();
    return (
      <ExternalLink
        to={`https://www.microsoft.com/${language}/wdsi/definitions/antimalware-definition-release-notes?requestVersion=${version}`}>
        {version}
      </ExternalLink>
    );
  }

  function KbArticleLink({ kbArticleId }: { kbArticleId: string }): ReactElement {
    const {
      i18n: { language }
    } = useTranslation();
    return (
      <ExternalLink to={`https://support.microsoft.com/${language}/search/results?query=${kbArticleId}`}>
        {kbArticleId}
      </ExternalLink>
    );
  }

  function Description({ kbArticleId, title, categories }: UpdateRow): ReactElement {
    if (!kbArticleId) {
      return <>{title}</>;
    }

    const linksInTitle: { index: number; kbArticleId?: string; defenderUpdateVersion?: string }[] = [];

    if (categories?.map(c => c.title)?.includes('Microsoft Defender Antivirus') && title.includes('Version')) {
      const matches = title.match(/\(Version (.*?)\)/);
      if (matches) {
        const versionString = matches[1];
        linksInTitle.push({ index: title.indexOf(versionString), defenderUpdateVersion: versionString });
      }
    }

    if (title.includes(kbArticleId)) {
      linksInTitle.push({ kbArticleId, index: title.indexOf(kbArticleId) });
    }

    linksInTitle.sort((a, b) => a.index - b.index);

    if (!linksInTitle.length) {
      return <>{title}</>;
    }

    const fragments: ReactElement[] = [];

    let position = 0;
    for (const { kbArticleId, index, defenderUpdateVersion } of linksInTitle) {
      fragments.push(<span key={index}>{title.substring(position, index)}</span>);
      if (kbArticleId) {
        fragments.push(<KbArticleLink key={kbArticleId} kbArticleId={kbArticleId} />);
        position = index + kbArticleId.length;
        continue;
      }

      if (defenderUpdateVersion) {
        fragments.push(<VirusDefinitionUpdateLink key={defenderUpdateVersion} version={defenderUpdateVersion} />);
        position = index + defenderUpdateVersion.length;
      }
    }
    fragments.push(<span key={position}>{title.substring(position)}</span>);

    return <>{fragments}</>;
  }

  function KbArticleLinks({ kbArticleId }: { kbArticleId?: string }): ReactElement | null {
    if (!kbArticleId) {
      return null;
    }

    return (
      <>
        {' '}
        (
        <span key={kbArticleId}>
          <KbArticleLink kbArticleId={kbArticleId} />
        </span>
        )
      </>
    );
  }

  return (
    <Typography variant={'body-small'} className={styles.text} lineClamp={2}>
      <Description {...data} />
      <KbArticleLinks kbArticleId={data?.kbArticleId} />
    </Typography>
  );
}

export default WindowsUpdateDescriptionRenderer;
