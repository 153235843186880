import { inject, injectable } from 'inversify';
import type EnvironmentConfig from '../../environments/EnvironmentConfig';
import { EnvironmentServices } from '../../environments/module';
import { GraphQLClient } from 'graphql-request';
import { UpdateRow } from '../types/frontend/update-row';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { GetAvailableUpdatesInput, getAvailableUpdatesQuery } from '../schema/graphql';
import { SortDirection, Update, UpdateSortField } from '../types/backend/update';
import WindowsUpdateSeverity from '../../windows-updates/models/WindowsUpdateSeverity';

@injectable()
export class PatchviewDataService {
  private graphqlClient: GraphQLClient;

  constructor(@inject(EnvironmentServices.EnvironmentConfig) private environment: EnvironmentConfig) {
    this.graphqlClient = new GraphQLClient(this.environment.patchviewApiUrl);
  }

  setToken(token: string): void {
    this.graphqlClient.setHeader('authorization', `Bearer ${token}`);
  }

  setEndpoint(endpoint: string): void {
    this.graphqlClient.setEndpoint(endpoint);
  }

  async getAvailableUpdates(
    { pageIndex, pageSize }: PaginationState,
    sorting: SortingState,
    criticalOnly: boolean,
    searchText?: string
  ): Promise<{ getAvailableUpdates: UpdateRow[] }> {
    const variables: GetAvailableUpdatesInput = {
      pagination: {
        offset: pageIndex,
        limit: pageSize
      },
      ...(sorting[0] && {
        sort: [
          {
            direction: sorting[0].desc ? SortDirection.Descending : SortDirection.Ascending,
            field: sorting[0].id as UpdateSortField
          }
        ]
      }),
      ...(criticalOnly && { severity: WindowsUpdateSeverity.Critical }),
      ...(searchText && { searchText })
    };
    const data = await this.graphqlClient.request<{ getAvailableUpdates: Update[] }>(
      getAvailableUpdatesQuery,
      variables
    );

    console.log('getAvailableUpdates received: ', data);

    function transformUpdates(data: { getAvailableUpdates: Update[] }): UpdateRow[] {
      return data.getAvailableUpdates.map(update => ({
        ...update,
        kbArticleId: 'KB' + update.kbArticleId,
        severity: update.severity
      }));
    }
    return { getAvailableUpdates: transformUpdates(data) };
  }
}
