import { TabPanel, Tabs, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ChangeEvent, Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useJobDeviceUpdates } from '../../hooks/use-update-job-device-updates.hook';
import useLocationBasedTabIndex, { TabDefinition } from '../../../core/components/tabs/useLocationBasedTabIndex';
import { TabsComponent } from '@getgo/chameleon-web';
import useActiveAndPastUpdatesLink from '../active-and-past-updates/useActiveAndPastUpdatesLink';
import useUpdateDetailsLink from '../update-details/useUpdateDetailsLink';
import ContentHeader from '../../../core/components/ContentHeader';
import JobDeviceOsUpdatesGrid from './JobDeviceOsUpdatesGrid';
import JobDeviceAppUpdatesGrid from './JobDeviceAppUpdatesGrid';
import { TrackedTab } from '../../../core/components/tracking/TrackedComponents';
import { JobDeviceUpdateOverviewTabSwitchedEvent } from '../../../core/models/UserTrackingEvents';
import { upperCaseFirstLetter } from '../../../core/utilities/utilities';
import clsx from 'clsx';
import styles from './JobDeviceUpdates.module.scss';

export type JobHistoryOverviewTabs = 'Operating system updates' | 'Application updates';

export default function JobDeviceUpdates(): ReactElement {
  const params = useParams();
  const { t } = useTranslation();
  const jobId = params.jobId as string;

  const { isLoading, allUpdates, jobName, deviceName } = useJobDeviceUpdates(jobId, params.deviceId as string);

  const activeAndPastUpdatesLink = useActiveAndPastUpdatesLink();
  const updateDetailsLink = useUpdateDetailsLink(jobId, jobName);
  const TABS: TabDefinition<JobHistoryOverviewTabs>[] = [
    {
      tabId: 'Operating system updates',
      locationSuffix: '/os-jobs-history',
      component: <JobDeviceOsUpdatesGrid updates={allUpdates.osUpdates} isLoading={isLoading} />
    },
    {
      tabId: 'Application updates',
      locationSuffix: '/app-jobs-history',
      component: <JobDeviceAppUpdatesGrid updates={allUpdates.appUpdates} isLoading={isLoading} />
    }
  ];

  const [activeTabIndex, setActiveTabIndex] = useLocationBasedTabIndex<JobHistoryOverviewTabs>(
    TABS,
    'Operating system updates'
  );

  const onTabChange = ({ currentTarget }: ChangeEvent<TabsComponent>): void => {
    if (currentTarget.activetab.id === activeTabIndex) {
      return;
    }
    setActiveTabIndex(currentTarget.activetab.id as JobHistoryOverviewTabs);
  };

  return (
    <>
      <ContentHeader
        title={
          <Typography variant="heading-medium" tag="h1">
            {deviceName && t('deviceDetails.title', { device: deviceName })}
          </Typography>
        }
        breadcrumbLinks={[activeAndPastUpdatesLink, updateDetailsLink]}
      />
      <Tabs className={styles.tabs} activeid={activeTabIndex} label="Tabs label" onChange={onTabChange}>
        {TABS.map(tab => (
          <Fragment key={tab.tabId}>
            <TrackedTab
              trackingEvent={new JobDeviceUpdateOverviewTabSwitchedEvent(tab.tabId)}
              key={tab.tabId}
              id={tab.tabId}>
              {upperCaseFirstLetter(tab.tabId)}
            </TrackedTab>
            <TabPanel className={clsx(styles.deviceUpdatesContainer)} id={tab.tabId}>
              {tab.component}
            </TabPanel>
          </Fragment>
        ))}
      </Tabs>
    </>
  );
}
