import { EmptyState, LinearProgress, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentHeader from '../core/components/ContentHeader';
import styles from './FirstScan.module.scss';
import { ReactComponent as GettingStartedIcon } from './getting-started-icon.svg';

function FirstScan(): ReactElement {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timeoutNumber = setTimeout(() => {
      // let's count 1 to 100, count one every 100ms
      setCounter(Math.min(counter + 1, 100));
    }, 100);
    return () => clearTimeout(timeoutNumber);
  }, [counter]);

  return (
    <div className={styles.wrapper}>
      <ContentHeader
        title={
          <Typography variant="heading-medium" tag="h1">
            {t('companyUpdatesOverview.title')}
          </Typography>
        }
      />
      <div className={styles.emptyStateWrapper}>
        <EmptyState
          icon={<GettingStartedIcon className={styles.firstTimeFlowIcon} />}
          title={t('gettingStarted.firstScan.title')}>
          <>
            <div className={styles.progressWrapper}>
              <LinearProgress value={counter} min={0} max={100} />
            </div>
            <div>
              <p>{t('gettingStarted.firstScan.description')}</p>
            </div>
          </>
        </EmptyState>
      </div>
    </div>
  );
}

export default FirstScan;
