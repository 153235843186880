import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Actions from '../../../core/components/actions/Actions';
import ContentHeader from '../../../core/components/ContentHeader';
import useCompanyUpdatesOverviewLink from '../CompanyUpdatesOverview/useCompanyUpdatesOverviewLink';
import JobHistoryGrid from '../../../patch-view/components/job-history/JobHistoryGrid';

function ActiveAndPastUpdates(): ReactElement {
  const { t } = useTranslation();
  const updatesOverviewLink = useCompanyUpdatesOverviewLink();

  return (
    <>
      <ContentHeader
        title={
          <Typography variant="heading-medium" tag="h1">
            {t('activeAndPastUpdates.title')}
          </Typography>
        }
        breadcrumbLinks={[updatesOverviewLink]}
        actions={<Actions></Actions>}
      />
      <JobHistoryGrid />
    </>
  );
}

export default ActiveAndPastUpdates;
