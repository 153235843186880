import { environment } from '../../environments/environment';
import { FeatureSwitch } from '../models/feature-switch';
import http from '../services/http/default-http-client';
import localStorageService from '../services/local-storage.service';
import logger from '../services/logger.service';
import { combineUrl } from '../utilities/utilities';
import { DateTime } from 'luxon';

const featureSwitchLocalStorageKey = 'patch-management-feature-switches';

interface EnabledSwitches {
  enabledSwitches: FeatureSwitch[];
  queriedAt?: number;
}

export class FeatureSwitchService {
  private _outstandingLoad?: Promise<void> = undefined;
  private _featureSwitches: EnabledSwitches = this.loadFeatureSwitchesFromCache();

  async isEnabled(featureSwitch: FeatureSwitch): Promise<boolean> {
    const enabledSwitches = await this.getEnabledSwitches();
    return enabledSwitches.includes(featureSwitch);
  }

  clearCache(): void {
    localStorageService.removeItem(featureSwitchLocalStorageKey);
  }

  private async getEnabledSwitches(): Promise<string[]> {
    if (
      this._featureSwitches.queriedAt &&
      DateTime.fromMillis(Date.now()).diff(DateTime.fromMillis(this._featureSwitches.queriedAt)).as('seconds') < 60
    ) {
      return Promise.resolve(this._featureSwitches.enabledSwitches);
    }
    await this.queryFeatureSwitches();
    return Promise.resolve(this._featureSwitches.enabledSwitches);
  }

  private async queryFeatureSwitches(): Promise<void> {
    try {
      if (!this._outstandingLoad) {
        this._featureSwitches.queriedAt = Date.now();
        this._outstandingLoad = http
          .get<FeatureSwitch[]>(combineUrl(environment.frontendApiUrl, 'feature-switches/enabled'))
          .then(switches => {
            this._featureSwitches.enabledSwitches = switches;
            localStorageService.setItem(featureSwitchLocalStorageKey, this._featureSwitches);
          });
      }
      await this._outstandingLoad;
      this._outstandingLoad = undefined;
    } catch (e) {
      logger.logError(e as Error);
    }
  }

  private loadFeatureSwitchesFromCache(): EnabledSwitches {
    try {
      return localStorageService.getItem(featureSwitchLocalStorageKey) ?? { enabledSwitches: [], queriedAt: undefined };
    } catch (e) {
      return { enabledSwitches: [], queriedAt: undefined };
    }
  }
}

const featureSwitchService = new FeatureSwitchService();
export default featureSwitchService;
