import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language } from '../../core/models/language';
import { Theme } from '../../core/models/theme';
import { Options } from '../../core/models/options';
import { RootState } from '../../core/store/root.store';

// state
export interface UiFrameState {
  navigationRoute: string;
  jwt: string;
  theme: Theme;
  language: Language;
  appPrefix: string;
  options: Options;
}

const initialState: UiFrameState = {
  navigationRoute: '/',
  jwt: '',
  theme: 'light',
  language: 'en-US',
  appPrefix: '',
  options: {
    'company-id': '',
    overridePatchviewApiUrl: ''
  }
};

// slice (actions and reducers)
export const uiFrameSlice = createSlice({
  name: 'uiFrame',
  initialState,
  reducers: {
    setNavigationRoute: (state, action: PayloadAction<string>) => {
      state.navigationRoute = action.payload;
    },
    setJwt: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setOptions: (state, action: PayloadAction<Options>) => {
      state.options = action.payload;
    },
    setMockUiFrameData: (state, action: PayloadAction<UiFrameState>) => action.payload,
    setAppPrefix: (state, action: PayloadAction<string>) => {
      state.appPrefix = action.payload;
    }
  }
});

// selectors
export const selectNavigationRoute = (state: RootState): string => state.uiFrame.navigationRoute;
export const selectJwt = (state: RootState): string => state.uiFrame.jwt;
export const selectTheme = (state: RootState): Theme => state.uiFrame.theme;
export const selectLanguage = (state: RootState): Language => state.uiFrame.language;
export const selectDateLocale = createSelector(selectLanguage, language => {
  switch (language) {
    case 'de-DE':
      return 'de';
    case 'es-ES':
      return 'es';
    case 'fr-FR':
      return 'fr';
    case 'it-IT':
      return 'it';
    case 'pt-BR':
      return 'pt';
    case 'en-US':
    default:
      return 'en';
  }
});
export const selectAppPrefix = (state: RootState): string => state.uiFrame.appPrefix;

export const { setNavigationRoute, setJwt, setTheme, setLanguage, setMockUiFrameData, setAppPrefix, setOptions } =
  uiFrameSlice.actions;

export default uiFrameSlice.reducer;
