import { ReactElement } from 'react';
import styles from './DevicesSelector.module.scss';
import DevicesGrid from './devices-grid/DevicesGrid';
import { UpdateRow } from '../../types/frontend/update-row';

type DevicesSelectorProps = {
  selectedUpdates: UpdateRow[];
};

export function DevicesSelector({ selectedUpdates }: DevicesSelectorProps): ReactElement {
  return (
    <div className={styles.devicesSelectorContainer}>
      <DevicesGrid selectedUpdates={selectedUpdates} />
    </div>
  );
}

export default DevicesSelector;
