import { ChangeEvent, Fragment, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabPanel, Tabs, Typography } from '@getgo/chameleon-web-react-wrapper';
import styles from './CompanyUpdatesOverview.module.scss';
import Tooltip from '../../../core/components/tooltips/Tooltip';
import { InfoOutlinedIcon } from '@getgo/chameleon-icons/react';
import ContentHeader from '../../../core/components/ContentHeader';
import ExternalLink from '../../../core/components/ExternalLink';
import DeviceUpdateStatusGrid from './DeviceUpdateStatusGrid/DeviceUpdateStatusGrid';
import CompanyDeviceUpdateStatus from '../../models/CompanyDeviceUpdateStatus';
import CompanyDeviceUpdateDrawer from '../UpdateDrawer/CompanyDeviceUpdateDrawer';
import { TabsComponent } from '@getgo/chameleon-web';
import useLocationBasedTabIndex from '../../../core/components/tabs/useLocationBasedTabIndex';
import GridFilterIds from '../../models/GridFilterIds';
import JobHistoryGrid from '../../../patch-view/components/job-history/JobHistoryGrid';

const TABS = [
  {
    tabId: 'patches' as OverviewTabs,
    locationSuffix: '/patches',
    component: <Fragment></Fragment>,
    useUrlFragmentFiltering: true
  },
  { tabId: 'history' as OverviewTabs, locationSuffix: '/history', component: <Fragment></Fragment> }
];

const FILTER_FRAGMENTS = [GridFilterIds.ALL, GridFilterIds.VULNERABLE_DEVICES];

export type OverviewTabs = 'patches' | 'history';

export default function CompanyUpdatesOverview({
  patchViewTabMode = false
}: {
  patchViewTabMode?: boolean;
}): ReactElement {
  const { t } = useTranslation();
  const [selectedDeviceUpdates, setSelectedDeviceUpdates] = useState<CompanyDeviceUpdateStatus[]>([]);
  const [installRequestPending, setInstallRequestPending] = useState(false);

  const [activeTabIndex, setActiveTabIndex, activeFilter, setActiveFilter] = useLocationBasedTabIndex<OverviewTabs>(
    TABS,
    'patches',
    FILTER_FRAGMENTS,
    GridFilterIds.ALL,
    patchViewTabMode
  );

  const onTabChange = ({ currentTarget }: ChangeEvent<TabsComponent>): void => {
    if (currentTarget.activetab.id === activeTabIndex) {
      return;
    }
    setActiveTabIndex(currentTarget.activetab.id as OverviewTabs);
  };

  const selectedDeviceIds = useMemo(() => {
    const selectedDeviceIds = new Set<string>();
    selectedDeviceUpdates.forEach(deviceUpdate => {
      selectedDeviceIds.add(deviceUpdate.device.id);
    });
    return selectedDeviceIds;
  }, [selectedDeviceUpdates]);

  return (
    <>
      {!patchViewTabMode && (
        <>
          <ContentHeader
            title={
              <Typography variant="heading-medium" tag="h1">
                {t('companyUpdatesOverview.title')}
              </Typography>
            }
          />
          <Tabs activeid={activeTabIndex} label="Tabs label" orientation="horizontal" onChange={onTabChange}>
            <Tab id="patches">Updates</Tab>
            <Tab id="history">History</Tab>
            <TabPanel></TabPanel>
            <TabPanel></TabPanel>
          </Tabs>
        </>
      )}
      {activeTabIndex === 'patches' && (
        <>
          <div className={styles.installInfoWrapper}>
            <div className={styles.installInfo}>
              <Tooltip
                position={'bottom-end'}
                max-width="300"
                trigger={
                  <ExternalLink
                    to="https://support.goto.com/resolve/help/working-with-windows-updates-in-goto-resolve"
                    variant={'body-small'}>
                    {t('companyUpdatesOverview.installInfo.link')}
                  </ExternalLink>
                }>
                {t('companyUpdatesOverview.installInfo.tooltip')}
              </Tooltip>
              <Tooltip
                position={'bottom-end'}
                max-width="300"
                trigger={<InfoOutlinedIcon class={styles.installInfoIcon} />}>
                {t('companyUpdatesOverview.installInfo.tooltip')}
              </Tooltip>
            </div>
          </div>

          <DeviceUpdateStatusGrid
            selectedDeviceIds={selectedDeviceIds}
            onDeviceSelectionChange={setSelectedDeviceUpdates}
            installRequestPending={installRequestPending}
            initialFilter={activeFilter}
            onFilterChanged={setActiveFilter}
          />

          {selectedDeviceUpdates.length > 0 && (
            <CompanyDeviceUpdateDrawer
              selectedDeviceUpdates={selectedDeviceUpdates.map(deviceUpdates => ({
                device: deviceUpdates.device,
                updates: deviceUpdates.updates
              }))}
              isInstallationPending={installRequestPending}
              setInstallationPending={setInstallRequestPending}
              onClose={() => setSelectedDeviceUpdates([])}
            />
          )}
        </>
      )}
      {activeTabIndex === 'history' && <JobHistoryGrid></JobHistoryGrid>}
    </>
  );
}
