import { RefreshFilledIcon } from '@getgo/chameleon-icons/react';
import { EmptyState, Typography } from '@getgo/chameleon-web-react-wrapper';
import { PropsWithChildren, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentHeader from '../core/components/ContentHeader';
import ExternalLink from '../core/components/ExternalLink';
import featureSwitchService from '../core/feature-switch/feature-switch.service';
import useFeatureSwitch from '../core/feature-switch/use-feature-switch.hook';
import { FeatureSwitch } from '../core/models/feature-switch';
import http from '../core/services/http/default-http-client';
import logger from '../core/services/logger.service';
import { combineUrl } from '../core/utilities/utilities';
import { environment } from '../environments/environment';
import FirstScan from './FirstScan';
import styles from './GettingStarted.module.scss';
import { TrackedButton } from '../core/components/tracking/TrackedComponents';

function GettingStarted({ children }: PropsWithChildren): ReactElement | null {
  const { t } = useTranslation();

  const isPatchManagementEnabled = useFeatureSwitch(FeatureSwitch.PatchManagement);
  const [isFirstScanLoading, setIsFirstScanLoading] = useState<boolean | undefined>(undefined);

  const handleGetStartedClick = async (): Promise<void> => {
    const then = performance.now();
    try {
      setIsFirstScanLoading(true);
      await http.post(combineUrl(environment.frontendApiUrl, 'feature-switches/enable-patch-management'), undefined);
      // we need to clear the cache of featureSwitchService so we don't show this page multiple times when user refreshes the page
      featureSwitchService.clearCache();
    } catch (e) {
      logger.logError(e as Error);
    } finally {
      setTimeout(
        () => {
          setIsFirstScanLoading(false);
          // let's wait at least 10 seconds to give the user the chance to read the texts we show
        },
        10000 - (performance.now() - then)
      );
    }
  };

  if (typeof isPatchManagementEnabled === 'undefined') {
    return null;
  }

  if (isFirstScanLoading) {
    return <FirstScan />;
  }

  if (isPatchManagementEnabled || isFirstScanLoading === false) {
    return <>{children}</>;
  }

  return (
    <div className={styles.wrapper}>
      <ContentHeader
        title={
          <Typography variant="heading-medium" tag="h1">
            {t('companyUpdatesOverview.title')}
          </Typography>
        }
      />
      <div className={styles.emptyStateWrapper}>
        <EmptyState
          icon={<RefreshFilledIcon color={'var(--goto-icon-color-brand-default)'} />}
          title={t('gettingStarted.title')}
          actions={
            <>
              <TrackedButton onClick={handleGetStartedClick} trackingEvent={{ name: 'Enable_WindowsUpdate' }}>
                {t('gettingStarted.actions.getStarted')}
              </TrackedButton>
              <ExternalLink to="https://support.goto.com/resolve/help/working-with-windows-updates-in-goto-resolve">
                {t('gettingStarted.actions.learnMore')}
              </ExternalLink>
            </>
          }>
          {t('gettingStarted.body')}
        </EmptyState>
      </div>
    </div>
  );
}

export default GettingStarted;
