import { ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from '../../store/root.store';
import { selectNavigationRoute } from '../../../ui-frame/state/ui-frame.slice';
import { navigateToPath } from '../../services/navigation.service';
import GridFilterIds from '../../../windows-updates/models/GridFilterIds';

export interface TabDefinition<T extends string> {
  tabId: T;
  locationSuffix: string;
  component?: ReactElement;
  useUrlFragmentFiltering?: boolean;
}

/**
 * Hook to help manage the active route based on the current tab.
 * This is _supposedly_ necessary due to the routing being handled by the UI Frame.
 *
 * @param tabDefinitions - The list of possible tabs.
 * @param defaultTabId - The default tab to use if no tab is found in the route.
 * @param filterDefinitions - The list of possible filters
 * @param defaultFilterId - The default filter to be used
 * @param patchViewTabMode - If true, the hook will not navigate (basically disabling it).
 *  */
export default function useLocationBasedTabIndex<T extends string>(
  tabDefinitions: TabDefinition<T>[],
  defaultTabId: T,
  filterDefinitions?: GridFilterIds[],
  defaultFilterId?: GridFilterIds,
  patchViewTabMode = false
): [T, (currentTabIndex: T) => void, GridFilterIds, (currentFilter: GridFilterIds) => void] {
  const navigationRoute = useAppSelector(selectNavigationRoute);

  const [activeTabId, setActiveTabId] = useState<T>(
    () => getTabIdFromRoute(navigationRoute, tabDefinitions) ?? defaultTabId
  );
  const [activeFilterId, setActiveFilterId] = useState<GridFilterIds>(
    () => getFilterIdFromRoute(navigationRoute, filterDefinitions) ?? defaultFilterId ?? GridFilterIds.ALL
  );

  useEffect(() => {
    const activeTabDefinition = tabDefinitions.find(tab => tab.tabId === activeTabId);
    const [activeTabSuffix, useUrlFragmentFiltering] = [
      activeTabDefinition?.locationSuffix,
      activeTabDefinition?.useUrlFragmentFiltering
    ];
    const [route, params] = splitParamsFromRoute(navigationRoute);
    const basePath = removeTabSuffixFromRoute(route, tabDefinitions);
    let navigateTo;
    if (useUrlFragmentFiltering) {
      if (patchViewTabMode) {
        navigateTo = basePath + activeFilterId + params;
      } else {
        navigateTo = basePath + activeTabSuffix + activeFilterId + params;
      }
    } else {
      navigateTo = basePath + activeTabSuffix + params;
    }

    if ((!patchViewTabMode || useUrlFragmentFiltering) && navigationRoute != navigateTo) {
      navigateToPath(navigateTo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabDefinitions, activeTabId, activeFilterId, filterDefinitions]);

  return [activeTabId, setActiveTabId, activeFilterId, setActiveFilterId];
}

function getTabIdFromRoute<T extends string>(navigationRoute: string, tabDefinitions: TabDefinition<T>[]): T | null {
  const matchingDefinition = tabDefinitions.find(tab => navigationRoute.endsWith(tab.locationSuffix));
  return matchingDefinition ? matchingDefinition.tabId : null;
}

function getFilterIdFromRoute(navigationRoute: string, tabDefinitions?: GridFilterIds[]): GridFilterIds | null {
  const url = new URL(navigationRoute, 'http://foo.com');
  const matchingDefinition = tabDefinitions?.find(filterId => url.hash === (filterId as string));
  return matchingDefinition ? matchingDefinition : null;
}

function splitParamsFromRoute(navigationRoute: string): [string, string] {
  const url = new URL(navigationRoute, 'http://foo.com');
  return [url.pathname, url.search];
}

function removeTabSuffixFromRoute<T extends string>(currentPath: string, tabDefinitions: TabDefinition<T>[]): string {
  const matchingDefinition = tabDefinitions.find(def => currentPath.endsWith(def.locationSuffix));
  return matchingDefinition ? currentPath.slice(0, -matchingDefinition.locationSuffix.length) : currentPath;
}
