import { TabsComponent } from '@getgo/chameleon-web';
import { TabPanel, Tabs, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ChangeEvent, Fragment, ReactElement } from 'react';
import useLocationBasedTabIndex, { TabDefinition } from '../../../core/components/tabs/useLocationBasedTabIndex';
import WindowsUpdatesSelector from '../../components/windows-updates-selector/WindowsUpdatesSelector';
import styles from './PatchManagementOverview.module.scss';
import { TrackedTab } from '../../../core/components/tracking/TrackedComponents';
import { PatchManagementOverviewTabSwitchedEvent } from '../../../core/models/UserTrackingEvents';
import { upperCaseFirstLetter } from '../../../core/utilities/utilities';
import { t } from '../../../i18n/i18n';
import JobHistoryGrid from '../../components/job-history/JobHistoryGrid';
import CompanyUpdatesOverview from '../../../windows-updates/components/CompanyUpdatesOverview/CompanyUpdatesOverview';
import clsx from 'clsx';

const TABS: TabDefinition<OverviewTabs>[] = [
  { tabId: 'windows updates', locationSuffix: '/windows-updates', component: <WindowsUpdatesSelector /> },
  {
    tabId: 'devices',
    locationSuffix: '/devices',
    component: <CompanyUpdatesOverview patchViewTabMode={true} />
  },
  { tabId: 'history', locationSuffix: '/history', component: <JobHistoryGrid /> }
];

export type OverviewTabs = 'windows updates' | 'devices' | 'history';

export function PatchManagementOverview(): ReactElement {
  const [activeTabIndex, setActiveTabIndex] = useLocationBasedTabIndex<OverviewTabs>(TABS, 'windows updates');

  const onTabChange = ({ currentTarget }: ChangeEvent<TabsComponent>): void => {
    if (currentTarget.activetab.id === activeTabIndex) {
      return;
    }
    setActiveTabIndex(currentTarget.activetab.id as OverviewTabs);
  };

  return (
    <div>
      <Typography className={styles.title} variant="heading-medium" tag="h1">
        {t('updatesOverviewPage.title')}
      </Typography>

      <Tabs className={styles.tabs} activeid={activeTabIndex} label="Tabs label" onChange={onTabChange}>
        {TABS.map(tab => (
          <Fragment key={tab.tabId}>
            <TrackedTab
              trackingEvent={new PatchManagementOverviewTabSwitchedEvent(tab.tabId)}
              key={tab.tabId}
              id={tab.tabId}>
              {upperCaseFirstLetter(tab.tabId)}
            </TrackedTab>
            <TabPanel className={clsx(activeTabIndex === 'devices' && styles.deviceUpdatesContainer)} id={tab.tabId}>
              {tab.component}
            </TabPanel>
          </Fragment>
        ))}
      </Tabs>
    </div>
  );
}
