import ActiveAndPastUpdates from '../../windows-updates/components/active-and-past-updates/ActiveAndPastUpdates';
import DeviceDetails from '../../windows-updates/components/DeviceDetails/DeviceDetails';
import JobDeviceUpdates from '../../windows-updates/components/job-device-updates/JobDeviceUpdates';
import UpdateDetails from '../../windows-updates/components/update-details/UpdateDetails';
import { ReactElement } from 'react';
import CompanyUpdatesOverview from '../../windows-updates/components/CompanyUpdatesOverview/CompanyUpdatesOverview';
import ScheduleUpdatesPage from '../../patch-view/pages/schedule-updates-wizard/ScheduleUpdatesWizard';
import { PatchManagementOverview } from '../../patch-view/pages/patch-management-overview/PatchManagementOverview';

export type GoToResolveAppRoute =
  | { path: '/alerts'; params?: never }
  | { path: '/devices'; params?: never }
  | { path: '/devices/details/:deviceId'; params: { deviceId: string } }
  | { path: '/devices/manage-devices'; params?: never }
  | {
      path: '/helpdesk/services/:serviceId/incidents/edit/:referenceId';
      params: { serviceId: string; referenceId: string };
    }
  | { path: '/remote-execution'; params?: never }
  | { path: '/remote-execution/runs/:runId/details'; params: { runId: string } };

export type AppRoute =
  | { path: '/'; params?: never }
  | { path: '/devices/:deviceId/updates'; params: { deviceId: string } }
  | { path: '/updates'; params?: never }
  | { path: '/updates/:updateJobId'; params: { updateJobId: string } }
  | { path: '/updates/:jobId/devices/:deviceId/updates'; params: { jobId: string; deviceId: string } }
  | { path: '/patch-view'; params?: never }
  | { path: '/patch-view/history'; params?: never }
  | { path: '/patch-view/windows-updates'; params?: never }
  | { path: '/patch-view/devices'; params?: never }
  | { path: '/history'; params?: never }
  | { path: '/schedule'; params?: never };

export type AppRoutePattern =
  | '/'
  | '/devices/:deviceId/updates/*'
  | '/updates'
  | '/updates/:updateJobId'
  | '/updates/:jobId/devices/:deviceId/updates'
  | '/updates/:jobId/devices/:deviceId/updates/os-jobs-history'
  | '/updates/:jobId/devices/:deviceId/updates/app-jobs-history'
  | '/patch-view/*'
  | '/history'
  | '/schedule'
  | '*';

type RouteObject = { path: AppRoutePattern; element: ReactElement };

export const routes: ReadonlyArray<RouteObject> = [
  { path: '/', element: <CompanyUpdatesOverview /> },
  { path: '/devices/:deviceId/updates/*', element: <DeviceDetails /> },
  { path: '/updates', element: <ActiveAndPastUpdates /> },
  { path: '/updates/:updateJobId', element: <UpdateDetails /> },
  { path: '/updates/:jobId/devices/:deviceId/updates', element: <JobDeviceUpdates /> },
  { path: '/updates/:jobId/devices/:deviceId/updates/os-jobs-history', element: <JobDeviceUpdates /> },
  { path: '/updates/:jobId/devices/:deviceId/updates/app-jobs-history', element: <JobDeviceUpdates /> },
  { path: '/patch-view/*', element: <PatchManagementOverview /> },
  { path: '/schedule', element: <ScheduleUpdatesPage /> },
  { path: '*', element: <CompanyUpdatesOverview /> }
];
