import { ReactElement } from 'react';
import UpdateDrawer from './UpdateDrawer';
import InfoPanel from '../../../core/components/info-panel/InfoPanel';
import styles from '../DeviceDetails/DeviceDetails.module.scss';
import { RefreshFilledIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { useTranslation } from 'react-i18next';
import DeviceUpdates from '../../models/DeviceUpdates';

interface DeviceInventoryUpdateDrawerProps {
  onClose: () => void;
  selectedDeviceUpdates: DeviceUpdates[];
  isInstallationPending: boolean;
  setInstallationPending: (isPending: boolean) => void;
  addUpdatesInProgress?: (updates: { id: string; version?: string }[]) => void;
}

export default function DeviceInventoryUpdateDrawer({
  onClose,
  selectedDeviceUpdates,
  isInstallationPending,
  setInstallationPending,
  addUpdatesInProgress
}: DeviceInventoryUpdateDrawerProps): ReactElement {
  const { t } = useTranslation();

  return (
    <UpdateDrawer
      onClose={onClose}
      selectedDeviceUpdates={selectedDeviceUpdates}
      isInstallationPending={isInstallationPending}
      setInstallationPending={setInstallationPending}
      installAppUpdates={true}
      installWindowsUpdates={true}
      installOptionalWindowsUpdates={true}
      addUpdatesInProgress={addUpdatesInProgress}>
      <InfoPanel className={styles.updateInfoPanel}>
        <div className={styles.updateInfoTitle}>
          <span>
            <RefreshFilledIcon size="24px" class={styles.drawerIcon} />
          </span>
          <Typography tag="h3">{t('updateDrawer.infoPanel.updates')}</Typography>
        </div>
        <div>
          <Typography variant="body-small" color="type-color-secondary">
            {t('updateDrawer.infoPanel.updatesSelected', { count: selectedDeviceUpdates[0].updates.length })}
          </Typography>
        </div>
      </InfoPanel>
    </UpdateDrawer>
  );
}
