import { useTranslation } from 'react-i18next';
import { AppLinkDef } from '../../../core/models/AppLinkDef';

export default function useCompanyUpdatesOverviewLink(): AppLinkDef {
  const { t } = useTranslation();
  return {
    route: { path: '/' },
    text: t('companyUpdatesOverview.title')
  };
}
