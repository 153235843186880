import '@abraham/reflection';
import * as chameleon from '@getgo/chameleon-web';
import { environment } from './environments/environment';
import './index.scss';
import CustomElement from './ui-frame/components/CustomElement';
import '@getgo/chameleon-web/components/registerAll';

ensureBaseHref();
ensureStyles().then(() => defineCustomElement()); // top-level await is not allowed

/** Defines the custom element used to mount the app in the UI frame in production. */
function defineCustomElement(): void {
  customElements.define('patch-management', CustomElement);
}

/** Ensures the base tag with correct href presents in the html that hosts the app (needed for the integration). */
function ensureBaseHref(): void {
  const baseElements = document.head.getElementsByTagName('base');
  if (baseElements?.length) {
    baseElements[0].setAttribute('href', environment.frontendUrl);
  } else {
    const base = document.createElement('base');
    base.href = environment.frontendUrl;
    document.head.appendChild(base);
  }
}

/** Ensures the global CSS link tag and add it before other styles. */
async function ensureStyles(): Promise<void> {
  return new Promise(resolve => {
    const link = document.createElement('link');
    link.href = `${environment.frontendUrl}/styles.css`;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.head.insertBefore(link, document.head.getElementsByTagName('base')[0]);

    link.onload = () => {
      resolve();
    };

    /**
     * since chrome v122, link.onload is not triggered when running on localhost
     * because the styles.css is not loaded properly. this is due to
     * `${environment.frontendUrl}/styles.css` returning a fallback html file
     * causing a content type mismatch and subsequently an opaque request blocking error.
     *
     * the dev server already loads this css file from memory, so the styling still works.
     * this is a workaround and resolves the promise when link.onerror is triggered to prevent
     * the app from hanging.
     */
    link.onerror = () => {
      resolve();
    };
  });
}
