import { ReactElement, useCallback, useEffect, useRef } from 'react';
import './DevicesGrid.module.scss';
import 'devices-component/components/tables/devices-selector-table';
import 'devices-component/components/tables/groups-selector-table';
import { useAppDispatch } from '../../../../core/store/root.store';
import { setDevices, setSelectedDeviceIds } from '../../../state/device-selector.slice';
import { DevicesSelectorTable } from '../../../types/frontend/devices-selector-table';
import { AdaptedDevice } from 'devices-component/types/adapted-device';
import { getDevices } from '../../../schema/graphql';
import { UpdateRow } from '../../../types/frontend/update-row';
import { useDevicesSelectorProps } from './useDevicesSelectorProps';

export type SelectedDeviceIds = {
  deviceIds?: string[];
  groupIds?: string[];
};

type SelectionChangedEventDetail = {
  deviceIds: string[];
  groupIds: string[];
  devices?: AdaptedDevice[];
};

type DevicesGridProps = {
  selectedUpdates: UpdateRow[];
};

export function DevicesGrid({ selectedUpdates }: DevicesGridProps): ReactElement {
  const dispatch = useAppDispatch();

  const devicesTableRef = useRef<HTMLDivElement>(null);

  const props = useDevicesSelectorProps(selectedUpdates);
  const handleSelectionChange = useCallback(
    (event: Event) => {
      const selectorTable: DevicesSelectorTable = devicesTableRef.current as any;
      const {
        detail: { deviceIds, groupIds }
      } = event as CustomEvent<SelectionChangedEventDetail>;

      dispatch(setDevices(selectorTable?.devices ?? []));

      dispatch(
        setSelectedDeviceIds({
          deviceIds: deviceIds.filter(x => x),
          groupIds: groupIds.filter(x => x)
        })
      );
    },
    [dispatch, devicesTableRef]
  );

  const handleFilterChanged = useCallback(() => {
    const selectorTable: DevicesSelectorTable = devicesTableRef.current as any;

    dispatch(
      setSelectedDeviceIds({
        deviceIds: undefined,
        groupIds: undefined
      })
    );
    dispatch(setDevices(selectorTable?.devices ?? []));
  }, [dispatch, devicesTableRef]);

  useEffect(() => {
    const element = devicesTableRef.current;

    // on-change event is not triggered when filters are changed...
    element?.addEventListener('on-change', handleSelectionChange);
    // ...so we need to listen to devices-loaded event to clear the selection
    element?.addEventListener('devices-loaded', handleFilterChanged);

    return () => {
      element?.removeEventListener('on-change', handleSelectionChange);
      element?.removeEventListener('devices-loaded', handleFilterChanged);
    };
  }, [dispatch, handleSelectionChange, handleFilterChanged]);

  return (
    <div>
      <devices-selector-table ref={devicesTableRef} query={getDevices} {...props} />
    </div>
  );
}

export default DevicesGrid;
