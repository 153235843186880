import WindowsUpdateSeverity from '../../windows-updates/models/WindowsUpdateSeverity';
import { Pagination, UpdateSortCriterion } from '../types/backend/update';

export type GetAvailableUpdatesInput = {
  pagination: Pagination;
  sort: UpdateSortCriterion[];
  searchText?: string;
  severity?: WindowsUpdateSeverity;
};

export const getAvailableUpdatesQuery = /* GraphQL */ `
  query getAvailableUpdatesQuery(
    $pagination: Pagination = { offset: 0, limit: 50 }
    $severity: Severity
    $searchText: String
    $sort: [UpdateSortCriterion!]
  ) {
    getAvailableUpdates(
      input: { pagination: $pagination, filters: { severity: $severity, searchText: $searchText }, sort: $sort }
    ) {
      ... on WindowsUpdate {
        id
        uuid
        title
        description
        severity
        software {
          id
          name
          vendor
        }
        kbArticleId
        requiresReboot
        availableOnDevicesCount
        categories {
          description
          uuid
          type
          title
        }
      }
    }
  }
`;

export const getDevices = /* GraphQL */ `
  query GetDevicesQuery(
    $ids: [String!]
    $offset: Int
    $first: Int
    $sort: Sort
    $verified: Boolean
    $deviceGroupIds: [String!]
    $premium: Boolean
    $platforms: [Platform!]
    $statuses: [StatusFilter!]
    $labels: [String!]
    $lastAccess: DateFilter
    $lastSeen: DateFilter
    $lastBootTime: DateFilter
    $av: AntiVirusFilters
    $patchmanagement: PatchManagementFilters
    $searchText: String
    $issueSeverities: [DeviceIssueSeverity!]
  ) {
    getDevices(
      input: {
        pagination: { offset: $offset, first: $first, sort: $sort }
        advancedFilters: {
          ids: $ids
          verified: $verified
          deviceGroupIds: $deviceGroupIds
          premium: $premium
          platforms: $platforms
          statuses: $statuses
          labels: $labels
          lastAccess: $lastAccess
          lastSeen: $lastSeen
          lastBootTime: $lastBootTime
          av: $av
          patchmanagement: $patchmanagement
          searchText: $searchText
          issueSeverities: $issueSeverities
        }
      }
    ) {
      id
      premium
      verified
      platform
      deviceGroupId
      inSession
      osName
      hostName
      timeZone
      deviceName
      status
      labels
      clientVersion
      osVersion
      ipAddresses
      lastLoggedInUser {
        userName
      }
      lastAccess
      firstSeen
      lastBootTime
      group {
        name
      }
      patchmanagement {
        availableUpdates {
          id
        }
      }
    }
    getAggregateBy(
      input: {
        advancedFilters: {
          verified: $verified
          deviceGroupIds: $deviceGroupIds
          premium: $premium
          platforms: $platforms
          statuses: $statuses
          labels: $labels
          lastAccess: $lastAccess
          lastBootTime: $lastBootTime
          av: $av
          patchmanagement: $patchmanagement
          searchText: $searchText
          issueSeverities: $issueSeverities
        }
      }
    ) {
      aggregate {
        totalDeviceCount
      }
    }
  }
`;
