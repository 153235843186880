import { ReactElement } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';
import { ReactComponent as SeverityCriticalIcon } from './severity-critical.svg';
import { ReactComponent as SeverityImportantIcon } from './severity-important.svg';
import { ReactComponent as SeverityLowIcon } from './severity-low.svg';
import { ReactComponent as SeverityModerateIcon } from './severity-moderate.svg';
import styles from './SeverityCellRenderer.module.scss';
import WindowsUpdateSeverity from '../../../../windows-updates/models/WindowsUpdateSeverity';

const severityIconMap: Record<WindowsUpdateSeverity, ReactElement> = {
  Critical: <SeverityCriticalIcon className={styles.critical} />,
  Important: <SeverityImportantIcon className={styles.important} />,
  Moderate: <SeverityModerateIcon className={styles.moderate} />,
  Low: <SeverityLowIcon className={styles.low} />,
  Unspecified: <SeverityLowIcon className={styles.low} />
};

const severityTranslationMap: Record<WindowsUpdateSeverity, TFuncKey> = {
  Critical: 'severity.critical',
  Important: 'severity.important',
  Moderate: 'severity.moderate',
  Low: 'severity.low',
  Unspecified: 'severity.low'
};

export default function SeverityCellRenderer(severity: WindowsUpdateSeverity): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.cell}>
      <>
        {severityIconMap[severity]}
        {t(severityTranslationMap[severity])}
      </>
    </div>
  );
}
