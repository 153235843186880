import { TooltipV3, Typography } from '@getgo/chameleon-web-react-wrapper/register';
import {
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
  TableState,
  createColumnHelper
} from '@tanstack/react-table';
import { PropsWithChildren, ReactElement, Fragment } from 'react';
import styles from './WindowsUpdatesGrid.module.scss';
import { Checkbox } from '@getgo/chameleon-web-react-wrapper';
import ChameleonGrid from '../../../core/chameleon-grid/ChameleonGrid';
import { UpdateRow } from '../../../types/frontend/update-row';
import { WindowsUpdatesCategory } from '../../../types/backend/update';
import WindowsUpdateDescriptionRenderer from '../../../core/chameleon-cell-renderers/WindowsUpdateDescriptionRenderer';
import WindowsReleaseNotesCellRenderer from '../../../core/chameleon-cell-renderers/WindowsReleaseNotesCellRenderer';
import { t } from '../../../../i18n/i18n';
import SeverityCellRenderer from '../../../core/chameleon-cell-renderers/SeverityCellRenderer/SeverityCellRenderer';

type Props = {
  data: UpdateRow[];
  onPaginationChange?: OnChangeFn<PaginationState>;
  pagination: PaginationState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  rowSelection: RowSelectionState;
  onSortingChange: OnChangeFn<SortingState>;
  sorting: SortingState;
  state?: Partial<TableState>;
  globalFilterEnabled: boolean;
  onGlobalFilterChange: OnChangeFn<any>;
  searchText: string;
  renderChips: () => ReactElement;
};

const columnHelper = createColumnHelper<any>();

function WindowsUpdatesGrid(props: Props): ReactElement {
  function CellText({ children }: PropsWithChildren): ReactElement {
    return (
      <Typography variant="caption-medium" className={styles.cellText} lineClamp={2}>
        {children}
      </Typography>
    );
  }

  const columns = [
    columnHelper.accessor('select', {
      enableSorting: false,
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          onClick={() => {
            table.toggleAllRowsSelected();
          }}></Checkbox>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onClick={() => {
            row.toggleSelected();
          }}></Checkbox>
      )
    }),
    columnHelper.accessor('title', {
      header: () => t('windowsUpdatesGrid.grid.headers.name'),
      cell: props => (
        <div id="full-name-trigger">
          {WindowsUpdateDescriptionRenderer(props.getValue(), props.row.original)}
          <TooltipV3 triggerId="full-name-trigger">{props.getValue()}</TooltipV3>
        </div>
      ),
      maxSize: 400
    }),
    columnHelper.accessor('categories', {
      header: () => t('windowsUpdatesGrid.grid.headers.category'),
      cell: props => <CellText>{props.getValue().map((c: WindowsUpdatesCategory) => c.title + ' ')}</CellText>
    }),
    columnHelper.accessor('severity', {
      header: () => t('windowsUpdatesGrid.grid.headers.severity'),
      cell: props => <CellText>{SeverityCellRenderer(props.getValue())}</CellText>
    }),
    columnHelper.accessor('availableOnDevicesCount', {
      header: () => t('windowsUpdatesGrid.grid.headers.devices'),
      cell: props => <CellText>{props.getValue() + ' ' + t('windowsUpdatesGrid.grid.headers.devices')}</CellText>
    }),
    columnHelper.accessor('kbArticleId', {
      header: () => t('windowsUpdatesGrid.grid.headers.kbNumber'),
      cell: props => WindowsReleaseNotesCellRenderer(props.getValue())
    })
  ];

  return (
    <Fragment>
      <ChameleonGrid classes={{ row: styles.row }} defaultColumn={{ size: undefined }} columns={columns} {...props} />
    </Fragment>
  );
}

export default WindowsUpdatesGrid;
