import { useMemo } from 'react';
import { useAppSelector } from '../../core/store/root.store';
import { t } from '../../i18n/i18n';
import { selectSelectedDeviceIds } from '../state/device-selector.slice';
import { selectSelectedUpdates } from '../state/windows-updates-selector-slice';

export const usePlaceholderText = (): string => {
  const selectedUpdates = useAppSelector(selectSelectedUpdates);
  const { deviceIds } = useAppSelector(selectSelectedDeviceIds);

  const updatesCount = selectedUpdates.length;
  const deviceCount = deviceIds?.length;

  return useMemo(() => {
    if (deviceCount && updatesCount) {
      const updatesTextKey = updatesCount === 1 ? 'placeHolderStart_one' : 'placeHolderStart';
      const devicesTextKey = deviceCount === 1 ? 'devicesPlaceHolder_oneEnd' : 'devicesPlaceHolderEnd';
      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
      const currentTime = new Date().toLocaleString().split(',')[1].split(':').slice(0, 2).join(':');
      return `${formattedDate} -${currentTime} - ${t(`reviewAndInstall.${updatesTextKey}`, {
        updates: updatesCount
      })} ${t(`reviewAndInstall.${devicesTextKey}`, { devices: deviceCount })}`;
    }
    return 'Missing devices or updates';
  }, [updatesCount, deviceCount]);
};
